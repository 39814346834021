import React, { useState, useEffect } from 'react'

//css
// import './styles.css'

//Service
import rest from '../../service/restAuth'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'

import TableGrid from '../../components/tableGrid'

//GridJS
import { h } from "gridjs"

const MaterialGroup = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isShowTable, setIsShowTable] = useState(true)
    const [materialGroups, setMaterialGroups] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedGroup, setSelectedGroup] = useState()

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findAllMaterialGroups = async () => {
        const response = await rest.get('/material/v1/findAllMaterialGroupsView')
        setMaterialGroupsData(response.data)
    }

    const registerMaterialGroup = async () => {
        let name = document.getElementById("edtMaterialGroupName").value

        if (name == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o nome do grupo',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/material/v1/saveGroup', {
                    name: name
                })

                document.getElementById("edtMaterialGroupName").value = ''
                await findAllMaterialGroups()
                setIsLoading(false)
                setOverMessage({
                    show: true,
                    message: 'Grupo cadastrado com sucesso',
                    type: 'success'
                })
                setIsLoading(false)
                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar o grupo',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerMaterialGroup()
    }

    // useEffect(() => {
    //     translateSearch()
    //     findAllMaterialGroups()
    // }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }


    const updateMaterialGroup = async () => {
        let name = document.getElementById("edtMaterialGroupNameUpdate").value

        if (name == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o grupo',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedGroup.id > 0) {
                    const response = await rest.post('/material/v1/updateGroup', {
                        id: selectedGroup.id,
                        name: name
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findAllMaterialGroups()
                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'Grupo alterado com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar um grupo',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar o grupo',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    // const changeSelectedGroup = (item) => {
    //     setSelectedGroup(item)
    // }

    // useEffect(() => {
    //     translateSearch()
    //     setSelectedGroup(null)
    // }, [materialGroups])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterialGroups(newData)
    }

    /* GridJS */

    const [materialGroupsData, setMaterialGroupsData] = useState([])
    const [materialGroupsColumns, setMaterialGroupsColumns] = useState([
        {
            id: 'id',
            name: 'id',
            hidden: true
        },
        {
            id: 'cod',
            name: 'Código'
        },
        {
            id: 'name',
            name: 'Nome'
        },
        {
            id: 'city',
            name: 'Cidade'
        },
        {
            name: 'Ação',
            formatter: (cell, row) => {
                return h('button', {
                    className: 'btn mainGradBK whiteFont',
                    onClick: () => selectedMaterialGroup(row.cells[0].data, row.cells[2].data)
                }, 'Editar')
            }
        },
        // {
        //     name: 'Ação',
        //     formatter: (cell, row) => {
        //         return h('button', {
        //             className: 'btn mainGradBK whiteFont',
        //             onClick: () => selectedHelpDesk(row.cells[0].data)
        //         }, 'PDF');
        //     }
        // },
    ])

    useEffect(() => {
        setIsShowTable(false)
        setSelectedGroup(null)
    }, [materialGroupsData])

    const selectedMaterialGroup = (materialGroupID, materialGroupNane) => {
        setSelectedGroup({
            id: materialGroupID,
            name: materialGroupNane
        })
    }

    useEffect(() => {
        if (selectedGroup) {
            if (selectedGroup.id > 0) {
                document.getElementById('btnUpdateMaterialGroupModal').click()
            }
        }
    }, [selectedGroup])

    const showMessage = (message, type) => {
        setOverMessage({
            show: true,
            message: message,
            type: type
        })
        setTimeout(() => {
            setOverMessage({
                show: false,
                message: '',
                type: ''
            })
        }, 4000)
    }

    useEffect(() => {
        const asyncLoadStart = async () => {
            await findAllMaterialGroups()
            setIsLoading(false)
        }
        asyncLoadStart()
    }, [])

    useEffect(() => {
        if (!isShowTable) {
            setIsShowTable(true)
            setIsLoading(false)
        }
    }, [isShowTable])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <div className='row'>
                            <div className="col-12">
                                <span className="title">Cadastro</span>
                                <p className="subTitle">Cadastrar Grupo</p>
                                <div className='mainDegLine'></div>
                            </div>
                        </div>
                        <div className="row top20">
                            <div className="col-12 col-md-3 top10">
                                <FieldText id="edtMaterialGroupName" name="edtMaterialGroupName" placeholder="Grupo" />
                            </div>
                            <div className="col-12 col-sm-2 top10">
                                <div onClick={e => registerMaterialGroup()}>
                                    <ButtonRectangle label="Cadastrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row top10 padding15">
                    <div className="col-12">
                        {
                            isShowTable &&
                            <TableGrid id="helpDeskGridId" title="Relatório" subTitle="Geranciar Grupos" columns={materialGroupsColumns} data={materialGroupsData} loadingFunction={setIsLoading} />
                        }
                    </div>
                </div>

                <button data-toggle="modal" id='btnUpdateMaterialGroupModal' data-target='#updateMaterialGroupModal' className='invisible btn mainGradBK whiteFont padding10 delay'></button>
                <div class="modal fade" id='updateMaterialGroupModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialGroupModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalLongTitle">Editar Grupo</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {
                                    selectedGroup &&
                                    <div className="row">
                                        {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> */}
                                        <div className="col-12 col-md-8 top10">
                                            <FieldText id="edtMaterialGroupNameUpdate" name="edtMaterialGroupNameUpdate" placeholder="Grupo" value={selectedGroup ? selectedGroup.name : ''} />
                                        </div>
                                        <div className="col-12 col-md-4 top10">
                                            <div onClick={e => updateMaterialGroup()}>
                                                <ButtonRectangle label="Salvar" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="modal-footer">
                                <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row top40">
                    <div className="col-12 col-sm-2">
                        {
                            materialGroups && <CSVLink
                                filename='arquivo.csv'
                                className="btn mainGradBK whiteFont btn-block padding10 padding20Side delay"
                                data={materialGroups}>
                                Download
                            </CSVLink>
                        }
                    </div>
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <table className="table table-hover">
                            <thead>
                                {
                                    materialGroups &&
                                    <TableFilter
                                        rows={materialGroups}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="name">
                                            Nome
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    materialGroups &&
                                    materialGroups.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateMaterialGroupModal' className='btn mainGradBK whiteFont padding10 delay' onClick={(e) => changeSelectedGroup(item)}>Editar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal fade" id='updateMaterialGroupModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialGroupModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar Grupo</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedGroup &&
                                        <div className="row">
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> * /}
                                            <div className="col-12 col-md-8 top10">
                                                <FieldText id="edtMaterialGroupNameUpdate" name="edtMaterialGroupNameUpdate" placeholder="Grupo" value={selectedGroup ? selectedGroup.name : ''} />
                                            </div>
                                            <div className="col-12 col-md-4 top10">
                                                <div onClick={e => updateMaterialGroup()}>
                                                    <ButtonRectangle label="Salvar" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> * /}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div >
    )
}

export default MaterialGroup