import { create } from 'apisauce'

const restZipCode = create({
    baseURL: 'https://viacep.com.br/ws/',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
})

//Para tratativa de erros
//Executa depois de receber o resultado e antes de enviar para o response no metodo que o chamou
restZipCode.addResponseTransform(response => {//Isso é um Middleware para resposta da API
    if (!response.ok) throw response //Assim vai para um cath que temos no method que chama o rest
});



//Middleware na requisição
restZipCode.addAsyncRequestTransform(request => async () => {
    //Recupera o token
    const token = await localStorage.getItem('@odin:token')
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`
})

export default restZipCode
