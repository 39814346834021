import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './config/css/animations.css'
import './config/css/animations-ie-fix.css'
import 'animate.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './config/js/js.js'
import './config/js/css3-animate-it.js'
import './App.css';

import 'react-table-filter/lib/styles.css'
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)