



window.$(document).ready(function () {
    var menu = window.$('#menu');
    function scroll() {
        if (window.$(window).scrollTop() > 0) {
        	window.$('#menu').removeClass('invisible');
            //window.$('#menu').addClass('visivel');
        	// window.$('#menu').removeClass('menu_nav_bar_zero');
            window.$('#menu').addClass('menu_nav_bar');
          /* Colcoar um padding noq vem em baixo da bar para ela n ficar em cima de conteudo -- window.$('.content').addClass('menu-padding'); */ 
        } else {
        	// window.$('#menu').removeClass('visivel');
            window.$('#menu').addClass('invisible');
            window.$('#menu').removeClass('menu_nav_bar');
            // window.$('#menu').addClass('menu_nav_bar_zero');
            /*  window.$('.content').removeClass('menu-padding');  remove o padding do que vem depois da bar pois nao precisa mais*/
            
        }
    }
    document.onscroll = scroll;
});

/*fim Scroll top*/


/* Scroll */
window.$(function() {
	  window.$('a[href*="#"]:not([href="#"])').click(function() {
	    if (window.location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && window.location.hostname == this.hostname) {
	      var target = window.$(this.hash);
	      target = target.length ? target : window.$('[name=' + this.hash.slice(1) +']');
	      if (target.length) {
	        window.$('html, body').animate({
	          scrollTop: target.offset().top
	        }, 1000);
	        return false;
	      }
	    }
	  });
	});


/*
/* AutoPlay * /
window.$(document).ready(function() {
	 window.$('#slider_principal').carousel({
	      interval: 4000
	    });
	});

window.$(document).ready(function() {
	 window.$('#slider_frases').carousel({
	      interval: 4000
	    });
	});
*/