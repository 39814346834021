import React, { useState, useEffect } from 'react'

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'
import TableFilter from 'react-table-filter'
import TableGrid from '../../components/tableGrid'

//PrimeReact
import { Chart } from 'primereact/chart';

//GridJS
import { h } from "gridjs";
// import "gridjs/dist/theme/mermaid.css";

const Users = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isShowTable, setIsShowTable] = useState(true)
    const [users, setUsers] = useState()
    const [citys, setCitys] = useState([])
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    // "id"`
    // Login         string `json:"login"`
    // Password      string `json:"password"`
    // Administrator string `json:"administrator"`
    // City          string `json:"city"`

    const [usersData, setUsersData] = useState([])
    const [userColumns, setUserColumns] = useState([
        {
            id: 'id',
            name: 'id',
            hidden: true
        },
        {
            id: 'login',
            name: 'Login'
        },
        {
            id: 'password',
            name: 'Senha'
        },
        {
            id: 'administrator',
            name: 'Administrador'
        },
        {
            id: 'city',
            name: 'Cidade'
        },
        // {
        //     name: 'Ação',
        //     formatter: (cell, row) => {
        //         return h('button', {
        //             className: 'btn mainGradBK whiteFont',
        //             onClick: () => selectedUser(row.cells[0].data)
        //         }, 'Editar');
        //     }
        // },
    ])

    useEffect(() => {
        setIsShowTable(false)
    }, [usersData])

    const selectedUser = (userID) => {
        console.log(userID)
    }

    const findUsers = async () => {
        const response = await rest.get('login/v1/findAllView')
        setUsersData(response.data)
    }

    const findCitys = async () => {
        const response = await rest.get('/city/v1/findAll')
        setCitys(response.data)
    }

    const registerUser = async () => {
        let username = document.getElementById("edtLogin").value
        let password = document.getElementById("edtPassword").value
        //let admCheckBox = document.getElementById("admCheckBox").checked
        let userLevel = document.getElementById("edtUserLevel").value
        let cityID = document.getElementById("edtCity").value
        if (username == '' || password == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar usuário e senha',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
                const response = await rest.post('/login/v1/registeruser', {
                    username: username,
                    password: password,
                    //isAdmin: admCheckBox ? true : false,
                    isAdmin: parseInt(userLevel) == 3 ? true : false,
                    //level: admCheckBox ? 3 : 2,
                    level: parseInt(userLevel),
                    cityId: parseInt(cityID)
                    // accessLevel: admCheckBox ? { id: 3 } : { id: 2 }
                })
                document.getElementById("edtLogin").value = ''
                document.getElementById("edtPassword").value = ''
                //document.getElementById("admCheckBox").checked = false
                document.getElementById("edtUserLevel").value = 2
                document.getElementById("edtCity").value = '0'
                await findUsers()
                setIsShowTable(false)
                setIsLoading(false)

                setOverMessage({
                    show: true,
                    message: 'Sucesso',
                    type: 'success'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 2000)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar usuário',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerUser()
    }

    const filterUpdated = (newData, filterConfiguration) => {
        setUsers(newData)
    }

    useEffect(() => {
        const asyncLoadStart = async () => {
            await findUsers()
            await findCitys()
        }
        asyncLoadStart()
    }, [])

    useEffect(() => {
        if (!isShowTable) {
            setIsShowTable(true)
        }
    }, [isShowTable])

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <div className="row">
                            <div className="col-12">
                                <span className="title">Cadastro</span>
                                <p className="subTitle">Cadastrar/Editar Usuários</p>
                                <div className='mainDegLine'></div>
                            </div>
                        </div>
                        <div className="row top40">
                            <div className="col-12 col-md-2 centerx">
                                {/* <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="admCheckBox" />
                                    <label class="form-check-label" for="admCheckBox">Administrador</label>
                                </div> */}
                                <div className="boxSelect">
                                    {
                                        <div id='boxSelectUserLevelId' className='borderGray'>
                                            <label for='' id='' className="labelSelect">Nível Acesso</label><br />
                                            <select name="edtUserLevel" id="edtUserLevel" className="selectOptionsBox borderNone">
                                                <option value={2} className="selectOption blackFont" selected>Técnico</option>
                                                <option value={3} className="selectOption blackFont">Administrador</option>
                                                <option value={4} className="selectOption blackFont">Prefeitura</option>
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <FieldText id="edtLogin" name="edtLogin" placeholder="Login" />
                            </div>
                            <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                <FieldText id="edtPassword" name="edtPassword" type="password" placeholder="Senha" />
                            </div>
                            <div className="col-12 col-sm-6">
                                {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> */}
                                <div className="boxSelect">
                                    {
                                        <div id='boxSelectProblemId' className='borderGray'>
                                            <label for='' id='' className="labelSelect">Cidade</label><br />
                                            <select name="edtCity" id="edtCity" className="selectOptionsBox borderNone">
                                                <option value='0' className="selectOption blackFont" selected>Selecionar Cidade</option>
                                                {
                                                    citys.map((item, k) => {
                                                        return (
                                                            <option key={k} value={item.id} className="selectOption blackFont">{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-3 top20">
                                <div onClick={e => registerUser()}>
                                    <ButtonRectangle label="Cadastrar/Alterar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row top10 padding15">
                    <div className="col-12">
                        {
                            isShowTable &&
                            <TableGrid id="usersGridId" title="Relatório" subTitle="Geranciar usuários" columns={userColumns} data={usersData} loadingFunction={setIsLoading} />
                        }
                    </div>
                </div>

                {/* <div className="row top40">
                    <div className="col-12">
                        <table className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    isShowTable &&
                                    users &&
                                    <TableFilter
                                        rows={users}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="username" filterkey="username" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Login
                                        </th>
                                        <th>
                                            Senha
                                        </th>
                                        {/* <th filterkey="isAdmin"> * /}
                                        <th>
                                            Administrador
                                        </th>
                                        <th filterkey="city.name">
                                            Cidade
                                        </th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    users &&
                                    users.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.username}</td>
                                            <td>******</td>
                                            <td>{item.isAdmin ? '*' : ''}</td>
                                            <td>{item.city.name}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}

export default Users