import React, { useState, useEffect } from 'react'

//css
import './styles.css'
import Inputmask from 'inputmask'

//Service
import rest from '../../service/restAuth'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import { PickList } from 'primereact/picklist'

const MaterialsGroups = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [materials, setMaterials] = useState()
    const [materialsUsage, setMaterialsUsage] = useState()
    const [materialsTotalUsage, setMaterialsTotalUsage] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedMaterial, setSelectedMaterial] = useState()
    const [materialGroups, setMaterialGroups] = useState([])
    const [subGroups, setSubGroups] = useState([])
    const [materialGroupID, setMaterialGroupID] = useState(0)
    const [userLevel, setUserLevel] = useState()
    const [isShowTable, setIsShowTable] = useState(true)
    const [materialPrice, setMaterialPrice] = useState('')
    const [selectedMaterialsSub, setSelectedMaterialsSub] = useState([])

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })


    const isValidUser = async () => {
        const userFromStorage = await localStorage.getItem('@lighthouse_jjeletrica:user')
        const user = JSON.parse(userFromStorage)
        // const user = localStorage.getItem('@lighthouse_jjeletrica:user')
        if (user) {
            setUserLevel(user.level)
        }
    }

    const findMaterialGroups = async () => {
        const response = await rest.get('material/v1/findAllMaterialGroups')
        setMaterialGroups(response.data)
    }

    const findMaterialsUsage = async () => {
        const response = await rest.get('view/v1/findmaterialUsageView')
        setMaterialsUsage(response.data)
    }

    const findSubGroups = async () => {
        setIsLoading(true)
        const response = await rest.get(`material/v1/findMaterialSubGroupsByGroupID/${materialGroupID}`)

        setSubGroups(response.data)
        setIsLoading(false)
    }

    const findMaterials = async () => {
        const response = await rest.get('material/v1/findAll')
        setMaterials(response.data)
    }

    const registerMeasurement = async () => {
        let measurement = document.getElementById("edtMeasurement").value
        if (measurement == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar a unidade de medida',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/measurement/v1/save', {
                    measurement: measurement
                })

                document.getElementById("edtMeasurement").value = ''
                await findMaterialGroups()
                setIsLoading(false)

                setOverMessage({
                    show: true,
                    message: 'Unidade de medida registrada com sucesso',
                    type: 'success'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 2000)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar unidade de medida',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const itemTemplate = (item) => {
        return (
            <div className="row">
                <div className="col-12">
                    <p>{item.name}</p>
                </div>
            </div>
        );
    }

    useEffect(() => {
        let fieldPeriodOne = document.getElementById("edtPeriodOne") ? document.getElementById("edtPeriodOne") : null
        let fieldPeriodTwo = document.getElementById("edtPeriodTwo") ? document.getElementById("edtPeriodTwo") : null

        var imDate = new Inputmask("99/99/9999")
        if (fieldPeriodOne != null) imDate.mask(fieldPeriodOne)
        if (fieldPeriodTwo != null) imDate.mask(fieldPeriodTwo)
        isValidUser()
        translateSearch()
        findMaterials()
        // findMaterialsUsage()
        findMaterialGroups()
    }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }

    const changeSelectedMaterial = (item) => {
        setSelectedMaterial(item)
        setSelectedMaterialsSub(item.materialSubGroups)
        // setSelectedMaterialsSub(item.subgroups) //tem que ter algo assim
        if (item.materialGroup) {
            if (item.materialGroup.id) {
                setMaterialGroupID(item.materialGroup.id)
            } else {
                setMaterialGroupID(0)
            }
        } else {
            setMaterialGroupID(0)
        }
    }

    const updateMaterial = async () => {
        if (parseInt(materialGroupID) > 0) {
            setIsLoading(true)

            //continuar aqui
            const response = await rest.post('/material/v1/registerGroupAndSubGroups', {
                material: {
                    id: parseInt(selectedMaterial.id)
                },
                materialGroup: {
                    id: parseInt(materialGroupID)
                },
                subGroups: selectedMaterialsSub
            })

            await findMaterials()

            //Fecha o modal
            document.getElementById('btnCloseModalId').click()

            setOverMessage({
                show: true,
                message: 'Vinculo registrado com sucesso',
                type: 'success'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
            setIsLoading(false)
        } else {
            setOverMessage({
                show: true,
                message: 'Favor selecionar um grupo',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        }
    }

    useEffect(() => {
        translateSearch()
        setSelectedMaterial(null)
    }, [materials])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterials(newData)
    }

    const filterUpdatedMaterialUsage = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterialsUsage(newData)
    }



    // const maskPrice = () => {
    //     try {
    //         if (materialPrice) {
    //             let v = materialPrice
    //             let integer = v.split(',')[0];

    //             v = v.replace(/\D/, "");
    //             v = v.replace(/^[0]+/, "");

    //             if (v.length <= 2 || !integer) {
    //                 if (v.length === 1) v = '0,0' + v;
    //                 if (v.length === 2) v = '0,' + v;
    //                 setMaterialPrice(v)
    //             } else {
    //                 v = v.replace(/^(\d{1,})(\d{2})$/, "$1,$2");
    //                 setMaterialPrice(v)
    //             }
    //         }
    //     } catch (e) { }

    // }

    // useEffect(() => {
    //     maskPrice()
    // }, [materialPrice])

    const getLenghtOfDefimalPrice = (price) => {
        let lengthAfterComman = (((price).toString()).split('.')[1]) ? (((price).toString()).split('.')[1]).length : 0
        if (lengthAfterComman == 2) {
            return (((price).toString())).replace('.', ',')
        } else if (lengthAfterComman == 1) {
            return (((price).toString() + '0')).replace('.', ',')
        } else if (lengthAfterComman == 0) {
            return (((price).toString() + ',00')).replace('.', ',')
        }
    }

    // const addSubGroup = async () => {
    //     if (parseInt(materialGroupID) > 0) {
    //         let newSelectedMaterialLst = []
    //         for (let i = 0; i < subGroups.length; i++) {
    //             if (document.getElementById('checkboxSubGroupID' + subGroups[i].id).checked) {
    //                 newSelectedMaterialLst.push(subGroups[i])
    //                 if (i + 1 == subGroups.length) {
    //                     // setSelectedMaterialsSub(newSelectedMaterialLst)
    //                     await updateMaterial(newSelectedMaterialLst)
    //                 }
    //             } else {
    //                 if (i + 1 == subGroups.length) {
    //                     // setSelectedMaterialsSub(newSelectedMaterialLst)
    //                     await updateMaterial(newSelectedMaterialLst)
    //                 }
    //             }
    //         }
    //     } else {
    //         setOverMessage({
    //             show: true,
    //             message: 'Favor selecionar um grupo',
    //             type: 'warning'
    //         })
    //         setTimeout(() => {
    //             setOverMessage({
    //                 show: false,
    //                 message: '',
    //                 type: ''
    //             })
    //         }, 4000)
    //     }
    // }

    // const addSubGroup = async (subGroup, inputID) => {
    //     if (!document.getElementById(inputID).checked) {//Add
    //         let newSelectedMaterialLst = []
    //         if (selectedMaterialsSub.length > 0) {
    //             let find = false
    //             for (let i = 0; i < selectedMaterialsSub.length; i++) {
    //                 const element = selectedMaterialsSub[i]

    //                 if (element.id == subGroup.id) {//edita
    //                     find = true
    //                     newSelectedMaterialLst.push(subGroup)
    //                 } else {//mantem os que já existem
    //                     newSelectedMaterialLst.push(element)
    //                 }

    //                 //Se executou pela o loop pela ultima vez
    //                 if (i + 1 == selectedMaterialsSub.length) {
    //                     if (!find) {//Adiciona o novo subGrupo
    //                         newSelectedMaterialLst.push(subGroup)
    //                     }
    //                     setSelectedMaterialsSub(newSelectedMaterialLst)
    //                 }
    //             }
    //             setSelectedMaterialsSub(newSelectedMaterialLst)
    //         } else {
    //             newSelectedMaterialLst.push(subGroup)
    //             setSelectedMaterialsSub(newSelectedMaterialLst)
    //         }
    //     } else {//Remove
    //         let newSelectedMaterialLst = []
    //         if (selectedMaterialsSub.length > 0) {
    //             let find = false
    //             for (let i = 0; i < selectedMaterialsSub.length; i++) {
    //                 const element = selectedMaterialsSub[i]
    //                 if (element.id != subGroup.id) {
    //                     newSelectedMaterialLst.push(element)
    //                 }

    //                 if (i + 1 == selectedMaterialsSub.length) {
    //                     setSelectedMaterialsSub(newSelectedMaterialLst)
    //                 }
    //             }
    //         }
    //     }
    // }

    const onChangeSelectSubGroups = (e) => {
        setSubGroups(e.source);
        setSelectedMaterialsSub(e.target);
    }

    const changeSelectedGroup = (materialGroupID) => {
        setMaterialGroupID(materialGroupID)
        setSelectedMaterialsSub([])
    }

    useEffect(() => {
        if (!isShowTable) {
            setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)
                setIsShowTable(true)
            }, 300)
        }
    }, [isShowTable])

    useEffect(() => {
        if (materialGroupID > 0) {
            // setSelectedMaterialsSub([])
            findSubGroups()
        }
    }, [materialGroupID])



    // useEffect(() => {
    //     //Desmarcas todos os checkbox de subgrupos
    //     for (let i = 0; i < subGroups.length; i++) {
    //         if (document.getElementById('checkboxSubGroupID' + subGroups[i].id))
    //             document.getElementById('checkboxSubGroupID' + subGroups[i].id).checked = false
    //     }
    // }, [subGroups])

    // useEffect(() => {
    //     console.log(selectedMaterialsSub)
    // }, [selectedMaterialsSub])

    const notInSelected = (subGroup) => {
        //se o index for menor que -1 então retorna TRUE pq queremos ele na lista de subgrupos
        if (selectedMaterialsSub) {
            if (selectedMaterialsSub.length > 0) {
                for (let index = 0; index < selectedMaterialsSub.length; index++) {
                    const element = selectedMaterialsSub[index];
                    if (subGroup.id == element.id) {
                        return false
                    }
                    //se for a ultima execução... e ainda não returnou false... o subgrupo não foi selecionado ainda
                    if (index + 1 == selectedMaterialsSub.length) {
                        return true
                    }
                }
            } else {
                return true
            }
        } else {
            return true
        }

    }

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row top40">
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <table className="table table-hover">
                            <thead>
                                {
                                    isShowTable &&
                                    materials &&
                                    <TableFilter
                                        rows={materials}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="name">
                                            Material
                                        </th>
                                        <th filterkey="city.name">
                                            Cidade
                                        </th>
                                        <th filterkey="city.uf">
                                            UF
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    materials &&
                                    materials.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.city.name}</td>
                                            <td>{item.city.uf}</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateMaterialModal' className='btn mainGradBK whiteFont padding20Side delay' onClick={(e) => changeSelectedMaterial(item)}>Grupo/SubGrupos</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal fade bd-example-modal-lg" id='updateMaterialModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialModal" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">{selectedMaterial ? selectedMaterial.name : 'Material'}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body center">
                                    {
                                        selectedMaterial &&
                                        <div className="row">
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                                    <FieldText id="edtDescriptionUpdate" name="edtDescriptionUpdate" placeholder="Description" value={selectedMaterial ? selectedMaterial.description : ''} />
                                                </div> */}
                                            <div className="col-12 top10">
                                                {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> */}
                                                <div className="boxSelect">
                                                    {
                                                        <div id='boxSelectMeasurementId' className='borderGray'>
                                                            {/* <label for='' id='' className="labelSelect">Unidade de Medida*</label><br /> */}
                                                            <select name="materialGroup" id="materialGroupID" className="selectOptionsBox borderNone" onChange={(e) => changeSelectedGroup(e.target.value)}>
                                                                <option value='0' className="selectOption blackFont">Grupo</option>
                                                                {
                                                                    materialGroups.map((item, k) => {
                                                                        return (
                                                                            item.id == materialGroupID ?
                                                                                <option key={k} value={item.id} className="selectOption blackFont" selected>{item.name}</option>
                                                                                :
                                                                                <option key={k} value={item.id} className="selectOption blackFont">{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    }

                                                    {/* {
                                                        selectedMaterialsSub &&
                                                        selectedMaterialsSub.length > 0 &&
                                                        <div className="padding30">
                                                            <p>SubGrupos selecionados</p>
                                                            <div className="line"></div>
                                                        </div>
                                                    } */}

                                                    {/* <div className="">
                                                        {
                                                            selectedMaterialsSub.map((item, k) => {
                                                                return (
                                                                    <div key={k}>
                                                                        <p><input id={`checkboxSelectedSubGroupID` + item.id} type="checkbox" value={item.id} onClick={(e) => { addSubGroup(item) }} />{item.id} - {item.name}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div> */}

                                                    {/* <div className="line"></div>
                                                    {
                                                        subGroups.map((item, k) => {
                                                            return (
                                                                <div key={k}>
                                                                    <p><input id={`checkboxSubGroupID` + item.id} type="checkbox" value={item.id} />{item.name}</p>
                                                                </div>
                                                            )
                                                        })
                                                    } */}
                                                </div>
                                            </div>
                                            <div className="col-12 top10 center">
                                                {/* Lista d esubgrupos sempre excluindo quem já tá na list ade subgrupos selecionados */}
                                                <PickList source={subGroups ? subGroups.filter(notInSelected) : []} target={selectedMaterialsSub} itemTemplate={itemTemplate}
                                                    sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                                                    onChange={onChangeSelectSubGroups} sourceHeader="SubGrupos" targetHeader="Selecionados" />
                                            </div>
                                            {/* <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtMaterialUpdate" name="edtMaterialUpdate" placeholder="Material" value={selectedMaterial ? selectedMaterial.name : ''} />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtMaterialPriceUpdate" name="edtMaterialPriceUpdate" placeholder="Preço" realType='priceType' value={selectedMaterial ? getLenghtOfDefimalPrice(selectedMaterial.price) : ''} />
                                            </div> */}
                                            <div className="col-12 top10 toRight1">
                                                <div onClick={e => updateMaterial()}>
                                                    <ButtonRectangle label="Salvar" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default MaterialsGroups