const isAdmAuthenticated = () => {
    // const token = localStorage.getItem('@lighthouse_jjeletrica:token')
    const userFromStorage = localStorage.getItem('@lighthouse_jjeletrica:user')
    const user = JSON.parse(userFromStorage)

    if (user.token && user.level == 3)
        return true
    else
        return false
}

export default isAdmAuthenticated