import React from 'react';
import './styles.css'

function IconCard(props) {
    //iconBoxWarning iconBoxSuccess iconBoxError iconBoxInfo
    return (
        <div className={`boxIconCard boxShadow 
            ${props.type == 'Aberto' ? 'iconBoxInfo' :
                props.type == 'Atrasado' ? 'iconBoxError' :
                    props.type == 'Concluído' ? 'iconBoxSuccess' : ''
            }
            `}>
            <div className="text-center center">
                <div className="padding10">
                    <div className="col-12">
                        <div className="row">
                            <span className="textOption">{props.title}</span>
                        </div>
                        <div className="row center">
                            <span className="textInfo">{props.info}</span>
                        </div>
                        {/* <div className="line"></div> */}
                        {/* <div className="row padding04">
                            <span className="textOption">{props.obs}</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default IconCard;

// import React from 'react';
// import './styles.css'

// function IconCard(props) {
//     return (
//         <div className="boxIconCard boxShadow">
//             <div className="">
//                 <div className="padding10">
//                     <div className="col-12">
//                         <div className="row topIconBox">
//                             <div className="col-6">
//                                 {
//                                     props.type == 'warning' ? (
//                                         <div className="iconBox boxShadow center iconBoxWarning">
//                                             I
//                                         </div>
//                                     )
//                                     :
//                                     props.type == 'success' ? (
//                                         <div className="iconBox boxShadow center iconBoxSuccess">
//                                             I
//                                         </div>
//                                     )
//                                     :
//                                     props.type == 'error' ? (
//                                         <div className="iconBox boxShadow center iconBoxError">
//                                             I
//                                         </div>
//                                     )
//                                     :
//                                     props.type == 'info' ? (
//                                         <div className="iconBox boxShadow center iconBoxInfo">
//                                             I
//                                         </div>
//                                     )
//                                     :
//                                     null
//                                 }
//                             </div>
//                             <div className="col-6">
//                                 <div className="row">
//                                     <span className="textOption">{props.title}</span>
//                                 </div>
//                                 <div className="row">
//                                     <span className="textInfo">{props.info}</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="line"></div>
//                         <div className="row padding04">
//                             <span className="textOption">{props.obs}</span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//     );
// }

// export default IconCard;