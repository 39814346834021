import React from 'react';
import './styles.css'

function FieldTextOpacity(props) {
  return (
    <div className="inputBoxOpacity padding03">
      <span className="iconBox">
        <i className={props.icon}></i>
      </span>
      <input type={props.type ? props.type : "text"} id={props.id} name={props.name} placeholder={props.placeholder} className="inputOpacity" />
    </div>
  );
}

export default FieldTextOpacity;
