import React from 'react'

function AlertMessage(props) {
    return (
        <div>
            {
                props.type == "error" ?
                    <div class="alert alert-danger errorBk boxShadow center" role="alert">
                        {props.msg}
                    </div>
                    :
                    <div class="alert alert-success successBk boxShadow center" role="alert">
                        {props.msg}
                    </div>
            }
        </div>
    )
}

export default AlertMessage