import React, { useState, useEffect } from 'react'

//css
import './style.css'

//Service
import rest from '../../service/restAuth'

//Components
import LoadingBar from '../../components/loadingBar'
import MenuBar from '../../components/menuBar'
import Inputmask from 'inputmask'
import OverMessage from '../../components/overMessage'


export default () => {
    const [isLoading, setIsLoading] = useState(false)
    const [tasks, setTasks] = useState()
    const [isShowTable, setIsShowTable] = useState(true)
    const [isChangeBySQL, setIsChangeBySQL] = useState(false)
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findTasks = async () => {
        try {
            setIsLoading(true)
            const response = await rest.get('/helpdesk/v1/findAll')
            console.log('response.data')
            console.log(response.data)
            setTasks(response.data)
            setIsLoading(false)
            setIsChangeBySQL(true)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const filterByDate = async () => {
        try {
            let validPeriodOne = document.getElementById('edtPeriodOne').value
            let validPeriodTwo = document.getElementById('edtPeriodTwo').value
            if (validPeriodOne == '' && validPeriodTwo == '') {// sem nenhum filtro, traz a base toda
                findTasks()
            } else if (validPeriodOne == '' || validPeriodTwo == '') {// não filtra pq precisa informar as duas datas(de/até)
                setOverMessage({
                    show: true,
                    message: 'Favor informar as duas datas (de/até)',
                    type: 'error'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } else {//filtra por período
                setIsLoading(true)
                const response = await rest.get(`/helpdesk/v1/findAllByPeriod/${validPeriodOne}-${validPeriodTwo}`)
                setTasks(response.data ? response.data : [])
                setIsLoading(false)
                setIsChangeBySQL(true)
            }
        } catch (e) {
            setIsLoading(false)
        }
    }

    window.onscroll = function (oEvent) {
        checkHeadTable()
    }

    const checkHeadTable = () => {
        if (window.pageYOffset >= 100) {
            // document.getElementById('headLineTableId').classList.add('topFixed')
        } else {
            // document.getElementById('headLineTableId').classList.remove('topFixed')
        }
    }

    useEffect(() => {
        let fieldPeriodOne = document.getElementById("edtPeriodOne") ? document.getElementById("edtPeriodOne") : null
        let fieldPeriodTwo = document.getElementById("edtPeriodTwo") ? document.getElementById("edtPeriodTwo") : null

        var imDate = new Inputmask("99/99/9999")
        if (fieldPeriodOne != null) imDate.mask(fieldPeriodOne)
        if (fieldPeriodTwo != null) imDate.mask(fieldPeriodTwo)
        findTasks()
    }, [])

    useEffect(() => {
        if (isChangeBySQL) {
            setIsLoading(true)
            setIsShowTable(false)
            setTimeout(() => {
                setIsLoading(false)
                setIsShowTable(true)
                setIsChangeBySQL(false)
            }, 300)
        }
    }, [isChangeBySQL])

    function binders() {
        document.querySelector('.js-check-all').addEventListener('click', toggleAllCheckboxes);
        document.querySelectorAll('.js-select-row').forEach(function (checkbox) {
            checkbox.addEventListener('click', function (e) {
                toggleEligibleForPrint(this.closest('tr').nextElementSibling, this.checked);
                togglePrintButton();
            });
        });

        document.querySelectorAll('.js-select-row').forEach(function (checkbox) {
            checkbox.addEventListener('click', function (e) {
                toggleEligibleForPrint(this.closest('tr').nextElementSibling, this.checked);
                togglePrintButton();
            });
        });

        document.querySelectorAll('.js-expand-data-row').forEach(function (expand) {
            expand.addEventListener('click', function (e) {
                e.preventDefault();
                toggleExpandData(e.currentTarget);
            });
        });

        document.querySelectorAll('.js-print-row-button').forEach(function (print) {
            print.addEventListener('click', function (e) {
                toggleEligibleForPrint(this.closest('tr').nextElementSibling, true);
                printData();
            });
        });

        document.querySelectorAll('.js-print-all-button').forEach(function (print) {
            print.addEventListener('click', function (e) {
                printData();
            });
        });
    }

    function toggleAllCheckboxes(event) {
        if (!event) return;
        var checkboxes = document.querySelectorAll('.js-select-row');
        var source = event.currentTarget;

        for (var i = 0, n = checkboxes.length; i < n; i++) {
            checkboxes[i].checked = source.checked;
            toggleEligibleForPrint(checkboxes[i].closest('tr').nextElementSibling, source.checked);
        }

        togglePrintButton();
    }

    function toggleEligibleForPrint(el, bool) {
        if (!el) return;
        el.classList.toggle('js-eligible-for-print', bool);
    }

    function togglePrintButton() {
        var selecteds = document.querySelectorAll('.js-eligible-for-print');
        var button = document.querySelector('.js-print-all-button');

        if (selecteds.length > 1) {
            button.querySelector('span').textContent = selecteds.length;
            button.style.visibility = 'visible';
        } else {
            button.style.visibility = 'hidden';
        }
    }

    function toggleExpandData(el) {
        if (!el) return;
        var expandRow = el.closest('tr').nextElementSibling;
        var target = expandRow.querySelector('.js-expand-row[data-ref="' + el.dataset.target + '"]');

        expandRow.querySelectorAll('.js-expand-row.active').forEach(function (expanded) {
            if (expanded !== target) {
                expanded.classList.remove("active");
            }
        });

        if (!target) throw 'Target is undefined';

        target.classList.toggle("active");
    }

    function printData() {
        var eligible = document.querySelectorAll('.js-eligible-for-print');

        if (eligible.length > 0) {
            var mywindow = window.open('', 'PRINT', 'height=800,width=600');
            var headTags = document.querySelectorAll("link[rel='stylesheet']");

            mywindow.document.write('<html><head>');
            mywindow.document.write('<link rel="stylesheet" href="page-print.css" type="text/css" />');
            if (headTags.length > 0) {
                for (var i = 0; i < headTags.length; i++) {
                    mywindow.document.write(headTags[i].outerHTML)
                }
            }
            mywindow.document.write('</head><body>');
            mywindow.document.write('<div class="page-header text-uppercase">');
            mywindow.document.write('	<img class="bg" src="img/jj-header.png" />');
            mywindow.document.write('	<div class="col-6 h-100 float-left p-2"><img class="logo" src="img/jj-logo.png" /></div>');
            mywindow.document.write('	<div class="col-6 h-100 float-right p-2 text-right">CNPJ: 29.793.736/0001-46 <br> IE: 258.598.190 | IM: 41457</div>');
            mywindow.document.write('</div>');
            mywindow.document.write('<div class="page-footer">');
            mywindow.document.write('	<img class="bg" src="img/jj-footer.png" />');
            mywindow.document.write('	<div class="p-2 text-right">Rua: Ouro preto, 373 - Bairro Benedito - Indaial/SC <br> Fone/Fax: (47) 3333-2652 <br> E-mail: jjinstaladora@outlook.com</div>');
            mywindow.document.write('</div>');

            for (var i = 0; i < eligible.length; i++) {
                var pageNumber = i + 1;
                mywindow.document.write('<page>');
                mywindow.document.write(eligible[i].querySelector('.js-print-page').outerHTML);
                mywindow.document.write('<strong class="text-muted page-number">Pag. ' + pageNumber + '</strong>');
                mywindow.document.write('</page>');
                mywindow.document.write('<div class="page-break"></div>');
            }

            mywindow.document.write('</body></html>');

            mywindow.document.close();
            mywindow.focus();

            mywindow.onload = function () {
                mywindow.print();
                // mywindow.close();
            };

            return true;
        }
    }

    useEffect(() => {
        binders()
    }, [])

    return (
        <div>
            <div></div>
            <div id="top">
                <div>
                    <nav id="menuFixed" class="navbar fixed-top navbar-expand-lg nav-light menu_nav_bar delay">
                        <a class="navbar-brand" href="#top"><img src="https://protocolosjjeletrica.com.br/static/media/logojj.f407aaf2.png" class="logoImg" /></a><button class="navbar-toggler blueMask01" type="button" data-toggle="collapse" data-target="#menuBar" aria-controls="menuBar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon center topLess1">-</span></button>
                        <div class="collapse navbar-collapse" id="menuBar">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item active menu_options"><a class="nav-link" href="/chamados">Chamados</a></li>
                                <li class="nav-item active menu_options"><a class="nav-link" href="/material ">Materiais</a></li>
                                <li class="nav-item active menu_options"><a class="nav-link" href="/pdfrelatorio ">Relatorio Atendimentos</a></li>
                                <li class="nav-item dropdown menu_options">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Opções</a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown"><a class="dropdown-item">Sair</a></div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="full padding100Top container">
                <div class="row top40">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="offset-lg-4 col-12 col-lg-10">
                                        <div class="row">
                                            <div class="row">
                                                <p>Data de atendimento</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-3 top10">
                                                    <div class="inputBox center padding03 delay" id="boxedtPeriodOne"><span class="iconBoxField"><i></i></span><input type="text" id="edtPeriodOne" name="edtPeriodOne" placeholder="Data" class="input" value="" inputmode="text" /></div>
                                                </div>
                                                <div class="col-12 col-md-3 top10">
                                                    <div class="inputBox center padding03 delay" id="boxedtPeriodTwo"><span class="iconBoxField"><i></i></span><input type="text" id="edtPeriodTwo" name="edtPeriodTwo" placeholder="Data" class="input" value="" inputmode="text" /></div>
                                                </div>
                                                <div class="col-12 col-md-3">
                                                    <div class="padding08">
                                                        <div>
                                                            <button type="button" class="btn btn-primary btn-block padding10 delay">
                                                                <div>Filtrar</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-5">
                        <div class="text-right mb-2">
                            <button class="btn btn-sm btn-primary js-print-all-button" style={{ visibility: 'hidden' }}>
                                <i class="align-middle p-button-icon p-c pi pi-print"></i>	 Imprimir selecionados (<span></span>)
                            </button>
                        </div>
                        <div class="table-responsive custom-table-responsive">
                            <table class="table custom-table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" class="js-check-all" title="Selecionar todos para impressão" />
                                        </th>
                                        <th scope="col">Protocolo</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Ocorrência</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <!-- ROW GROUP --> */}
                                    <tr scope="row">
                                        <th scope="row">
                                            <input type="checkbox" class="js-select-row" title="Selecionar linha para imprimir" />
                                        </th>
                                        <td>
                                            202107111939411609
                                        </td>
                                        <td>
                                            <a href="" class="js-expand-data-row" data-target="client">INDAIAL TESTE</a>
                                        </td>
                                        <td>Outros</td>
                                        <td>
                                            <small class="d-block" title="Abertura" ><i class="p-button-icon p-c pi pi-arrow-up"></i> 11/07/2021</small>
                                            <small class="d-block" title="Fechamento"><i class="p-button-icon p-c pi pi-arrow-down"></i> 11/07/2021</small>
                                        </td>
                                        <td>
                                            <i title="Fechado" class="p-button-icon p-c pi pi-check text-success d-inline-block"></i>
                                            /
                                            <i title="Aberto" class="p-button-icon p-c pi pi-flag text-info d-flag d-inline-block"></i>
                                            /
                                            <i title="Atrasado" class="p-button-icon p-c pi pi-clock text-warning d-inline-block"></i>
                                        </td>
                                        <td>
                                            <button class="p-button p-component p-button-icon-only js-expand-data-row" data-target="photos" title="Ver fotos">
                                                <i class="p-button-icon p-c pi pi-images"></i>
                                            </button>
                                            <button class="p-button p-component p-button-icon-only js-expand-data-row" data-target="client" title="Endereço">
                                                <i class="p-button-icon p-c pi pi-map-marker"></i>
                                            </button>
                                            <button class="p-button p-component p-button-icon-only js-print-row-button" title="Imprimir">
                                                <i class="p-button-icon p-c pi pi-print"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="expand-row">
                                        <td colspan="10">
                                            <div class="page js-print-page">
                                                {/* <!-- CLIENT DATA --> */}
                                                <div class="row-extra-data js-expand-row" data-ref="client">
                                                    <div class="d-flex w-100">
                                                        {/* <!-- ADDRESS --> */}
                                                        <div class="col-6">
                                                            <div class="card h-100">
                                                                <div class="card-body">
                                                                    <div class="d-block mb-3">
                                                                        <div class="inline padding08">
                                                                            <div class="labelTaskTitle text-uppercase">Indaial Teste</div>
                                                                        </div>
                                                                        <div class="line"></div>
                                                                    </div>

                                                                    <div class="row flex-wrap text-left">
                                                                        <div class="col-12"><strong class="labelTask mr-1">Contato:</strong></div>
                                                                        <div class="col-12"><hr /></div>
                                                                        <div class="col-12"><strong class="labelTask mr-1">Rua:</strong></div>
                                                                        <div class="col-12"><strong class="labelTask mr-1">Cidade:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">CEP:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Bairro:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Referência:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Complemento:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Lote:</strong></div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <!-- EVENT --> */}
                                                        <div class="col-6">
                                                            <div class="card h-100">
                                                                <div class="card-body">
                                                                    <div class="d-block mb-3">
                                                                        <div class="inline padding08">
                                                                            <div class="labelTaskTitle text-uppercase">Chamado</div>
                                                                        </div>
                                                                        <div class="line"></div>
                                                                    </div>

                                                                    <div class="row flex-wrap text-left">
                                                                        <div class="col-12"><strong class="labelTask mr-1">Protocolo:</strong></div>
                                                                        <div class="col-12"><hr /></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Ocorrência:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Status:</strong></div>
                                                                        <div class="col-12"><strong class="labelTask mr-1">Descrição:</strong></div>
                                                                        <div class="col-12"><hr /></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Abertura:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Fechamento:</strong></div>
                                                                        <div class="col-12"><strong class="labelTask mr-1">Descrição do atendimento:</strong></div>
                                                                        <div class="col-6"><strong class="labelTask mr-1">Encerrado por:</strong></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!-- PHOTO DATA --> */}
                                                <div class="row-extra-data js-expand-row" data-ref="photos">
                                                    <div class="w-100">
                                                        {/* <!-- MATERIALS --> */}
                                                        <div class="col-12">
                                                            <div class="d-block mb-3">
                                                                <div class="inline padding08">
                                                                    <div class="labelTaskTitle text-uppercase">Materiais</div>
                                                                </div>
                                                                <div class="line"></div>
                                                            </div>
                                                            <div class="card">
                                                                <table class="table table-striped m-0 table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="p-1 text-dark" scope="col">Código</th>
                                                                            <th class="p-1 text-dark" scope="col">Material/Serviço</th>
                                                                            <th class="p-1 text-dark" scope="col">Quant</th>
                                                                            <th class="p-1 text-dark" scope="col">Unidade</th>
                                                                            <th class="p-1 text-dark" scope="col">Preço</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tr>
                                                                        <td>45167</td>
                                                                        <td>Lâmpada vapor sodio 70w</td>
                                                                        <td>14</td>
                                                                        <td>3,97</td>
                                                                        <td>55,50</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>45167</td>
                                                                        <td>Lâmpada vapor sodio 70w</td>
                                                                        <td>14</td>
                                                                        <td>3,97</td>
                                                                        <td>55,50</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        {/* <!-- PHOTOS --> */}
                                                        <div class="col-12">
                                                            <div class="d-block mb-3">
                                                                <div class="inline padding08">
                                                                    <div class="labelTaskTitle text-uppercase">Fotos</div>
                                                                </div>
                                                                <div class="line"></div>
                                                            </div>

                                                            <div class="d-flex flex-wrap text-left mt-4">
                                                                <img src="https://place-hold.it/400x400/c7ac7a.jpg&text=PLaceholder" class="w-50 p-3 m-auto img-thumbnail" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="full20">
                <div class="full20 mainColorBK center whiteFont topBlueBorder">
                    <div class="container row">
                        <div class="row col-12">
                            <div class="col-6">
                                <h6><img src="https://protocolosjjeletrica.com.br/static/media/logojj.f407aaf2.png" class="logoImg" /></h6>
                            </div>
                            <div class="col-6 text-right">
                                <h6>© 2021 Todos os direitos reservados</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}