import React, { useState, useEffect } from 'react'

//css
import './styles.css'
import Inputmask from 'inputmask'

//Service
import rest from '../../service/restAuth'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import TableGrid from '../../components/tableGrid'

//GridJS
import { h } from "gridjs"

const Material = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [materials, setMaterials] = useState()
    const [materialsUsage, setMaterialsUsage] = useState()
    const [materialsTotalUsage, setMaterialsTotalUsage] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedMaterial, setSelectedMaterial] = useState()
    const [measurements, setMeasurements] = useState([])
    const [measurementID, setMeasurementID] = useState(0)
    const [userLevel, setUserLevel] = useState()
    const [isShowTable, setIsShowTable] = useState(true)
    const [materialPrice, setMaterialPrice] = useState('')

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const isValidUser = async () => {
        const userFromStorage = await localStorage.getItem('@lighthouse_jjeletrica:user')
        const user = JSON.parse(userFromStorage)
        // const user = localStorage.getItem('@lighthouse_jjeletrica:user')
        if (user) {
            setUserLevel(user.level)
        }
    }

    const findMeasurements = async () => {
        const response = await rest.get('measurement/v1/findAll')
        setMeasurements(response.data)
    }

    const findMaterialsUsage = async () => {
        const response = await rest.get('view/v1/findmaterialUsageView')
        setMaterialsUsage(response.data)
    }

    const findMaterialsTotalUsage = async () => {
        const response = await rest.get('view/v1/findmaterialTotalUsageView')
        setMaterialsTotalUsage(response.data)
    }

    const findMaterials = async () => {
        const response = await rest.get('material/v1/findAllView')
        setMaterialData(response.data)
    }

    const registerMaterial = async () => {
        let material = document.getElementById("edtMaterial").value
        // let description = document.getElementById("edtDescription").value
        // let measurementID = document.getElementById("edtMeasurementID").value
        let price = document.getElementById("edtMaterialPrice").value
        if (material == '' || measurementID == 0 || price == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar o nome do material',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                console.log('obj....')
                console.log({
                    name: material,
                    measurement: {
                        id: parseInt(measurementID)
                    },
                    price: parseFloat(price.replace(',', '.')),
                    description: ''
                })

                const response = await rest.post('/material/v1/save', {
                    name: material,
                    measurement: {
                        id: parseInt(measurementID)
                    },
                    price: parseFloat(price.replace(',', '.')),
                    description: ''
                })

                document.getElementById("edtMaterial").value = ''
                // document.getElementById("edtDescription").value = ''
                // document.getElementById("edtMeasurementID").value = ''
                setMeasurementID(0)
                document.getElementById("edtMaterialPrice").value = ''

                await findMaterials()
                await findMaterialsTotalUsage()
                setIsLoading(false)

                setIsShowTable(false)

                setOverMessage({
                    show: true,
                    message: 'Material registrado com sucesso',
                    type: 'success'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 2000)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar material',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const registerMeasurement = async () => {
        let measurement = document.getElementById("edtMeasurement").value
        if (measurement == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar a unidade de medida',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/measurement/v1/save', {
                    measurement: measurement
                })

                document.getElementById("edtMeasurement").value = ''
                await findMeasurements()
                setIsLoading(false)

                setOverMessage({
                    show: true,
                    message: 'Unidade de medida registrada com sucesso',
                    type: 'success'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 2000)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar unidade de medida',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerMaterial()
    }

    useEffect(() => {
        let fieldPeriodOne = document.getElementById("edtPeriodOne") ? document.getElementById("edtPeriodOne") : null
        let fieldPeriodTwo = document.getElementById("edtPeriodTwo") ? document.getElementById("edtPeriodTwo") : null

        var imDate = new Inputmask("99/99/9999")
        if (fieldPeriodOne != null) imDate.mask(fieldPeriodOne)
        if (fieldPeriodTwo != null) imDate.mask(fieldPeriodTwo)
        isValidUser()
        translateSearch()
        findMaterials()
        findMaterialsUsage()
        findMaterialsTotalUsage()
        findMeasurements()
    }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }

    // const changeSelectedMaterial = (item) => {
    //     setSelectedMaterial(item)
    //     setMeasurementID(item.measurement.id)
    // }

    const updateMaterial = async () => {
        let material = document.getElementById("edtMaterialUpdate").value
        // let description = document.getElementById("edtDescriptionUpdate").value
        // let measurementIDUpdate = document.getElementById("edtMeasurementIDUpdate").value
        let price = document.getElementById("edtMaterialPriceUpdate").value

        if (material == '' || measurementID == 0 || price == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar todos os campos do material',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedMaterial.id > 0) {
                    const response = await rest.post('/material/v1/update', {
                        id: selectedMaterial.id,
                        name: material,
                        description: '',
                        price: parseFloat(price.replace(',', '.')),
                        measurement: {
                            id: parseInt(measurementID)
                        }
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findMaterials()
                    await findMaterialsTotalUsage()

                    setIsShowTable(false)

                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'Material alterado com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar um material',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha o material',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        translateSearch()
        setSelectedMaterial(null)
    }, [materials])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterials(newData)
    }

    const filterUpdatedMaterialUsage = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterialsUsage(newData)
    }

    const filterByDate = async () => {
        try {
            let validPeriodOne = document.getElementById('edtPeriodOne').value
            let validPeriodTwo = document.getElementById('edtPeriodTwo').value
            if (validPeriodOne == '' && validPeriodTwo == '') {// sem nenhum filtro, traz a base toda
                setIsLoading(true)
                await findMaterialsUsage()
                await findMaterialsTotalUsage()
                setIsLoading(false)
            } else if (validPeriodOne == '' || validPeriodTwo == '') {// não filtra pq precisa informar as duas datas(de/até)
                setOverMessage({
                    show: true,
                    message: 'Favor informar as duas datas (de/até)',
                    type: 'error'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } else {//filtra por período
                setIsLoading(true)
                const response = await rest.get(`/view/v1/findmaterialUsageViewByPeriod/${validPeriodOne}-${validPeriodTwo}`)
                setMaterialsUsage(response.data ? response.data : [])

                const responsee = await rest.get(`/view/v1/findmaterialTotalUsageViewByPeriod/${validPeriodOne}-${validPeriodTwo}`)
                setMaterialsTotalUsage(responsee.data ? responsee.data : [])

                setIsLoading(false)
                setIsShowTable(false)
            }
        } catch (e) {
            setIsLoading(false)
        }
    }

    // const maskPrice = () => {
    //     try {
    //         if (materialPrice) {
    //             let v = materialPrice
    //             let integer = v.split(',')[0];

    //             v = v.replace(/\D/, "");
    //             v = v.replace(/^[0]+/, "");

    //             if (v.length <= 2 || !integer) {
    //                 if (v.length === 1) v = '0,0' + v;
    //                 if (v.length === 2) v = '0,' + v;
    //                 setMaterialPrice(v)
    //             } else {
    //                 v = v.replace(/^(\d{1,})(\d{2})$/, "$1,$2");
    //                 setMaterialPrice(v)
    //             }
    //         }
    //     } catch (e) { }

    // }

    // useEffect(() => {
    //     maskPrice()
    // }, [materialPrice])

    const getLenghtOfDefimalPrice = (price) => {
        let lengthAfterComman = (((price).toString()).split('.')[1]) ? (((price).toString()).split('.')[1]).length : 0
        if (lengthAfterComman == 2) {
            return (((price).toString())).replace('.', ',')
        } else if (lengthAfterComman == 1) {
            return (((price).toString() + '0')).replace('.', ',')
        } else if (lengthAfterComman == 0) {
            return (((price).toString() + ',00')).replace('.', ',')
        }
    }

    useEffect(() => {
        if (!isShowTable) {
            setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)
                setIsShowTable(true)
            }, 300)
        }
    }, [isShowTable])

    /* GridJS */

    const [materialData, setMaterialData] = useState([])
    const [materialColumns, setMaterialColumns] = useState([
        {
            id: 'id',
            name: 'id',
            hidden: true
        },
        {
            id: 'cod',
            name: 'Código'
        },
        {
            id: 'material',
            name: 'Material'
        },
        {
            id: 'measurementID',
            name: 'codMedida',
            hidden: true
        },
        {
            id: 'measurement',
            name: 'Unidade de medida'
        },
        {
            id: 'price',
            name: 'Preço'
        },
        {
            id: 'city',
            name: 'Cidade'
        },
        {
            name: 'Ação',
            formatter: (cell, row) => {
                return h('button', {
                    className: 'btn mainGradBK whiteFont',
                    onClick: () => changeSelectedMaterial(row.cells[0].data, row.cells[2].data, row.cells[3].data, row.cells[5].data)
                }, 'Editar')
            }
        },
        // {
        //     name: 'Ação',
        //     formatter: (cell, row) => {
        //         return h('button', {
        //             className: 'btn mainGradBK whiteFont',
        //             onClick: () => selectedHelpDesk(row.cells[0].data)
        //         }, 'PDF');
        //     }
        // },
    ])

    useEffect(() => {
        setIsShowTable(false)
        setSelectedMaterial(null)
    }, [materialData])

    const changeSelectedMaterial = (materialID, materialName, measurementID, materialPrice) => {
        setSelectedMaterial({
            id: materialID,
            name: materialName,
            description: '',
            price: parseFloat(materialPrice.replace(',', '.')),
            measurement: {
                id: parseInt(measurementID)
            }
        })
        setMeasurementID(measurementID)
    }

    useEffect(() => {
        if (selectedMaterial) {
            if (selectedMaterial.id > 0) {
                document.getElementById('btnUpdateMaterialModal').click()
            }
        }
    }, [selectedMaterial])

    const showMessage = (message, type) => {
        setOverMessage({
            show: true,
            message: message,
            type: type
        })
        setTimeout(() => {
            setOverMessage({
                show: false,
                message: '',
                type: ''
            })
        }, 4000)
    }

    useEffect(() => {
        const asyncLoadStart = async () => {
            await findMaterials()
            setIsLoading(false)
        }
        asyncLoadStart()
    }, [])

    useEffect(() => {
        if (!isShowTable) {
            setIsShowTable(true)
            setIsLoading(false)
        }
    }, [isShowTable])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            {
                userLevel == 3 &&

                <div className="full padding100Top container">
                    <div className="row">
                        <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                            <div className="row">
                                <div className="col-12">
                                    <span className="title">Cadastro</span>
                                    <p className="subTitle">Cadastrar Unidade de Medida - não considera cidade</p>
                                    <div className='mainDegLine'></div>
                                </div>
                                <div className="col-12 col-md-3 top10">
                                    <FieldText id="edtMeasurement" name="edtMeasurement" placeholder="Unidade de medida" />
                                </div>
                                <div className="col-12 col-sm-2 top10">
                                    <div onClick={e => registerMeasurement()}>
                                        <ButtonRectangle label="Cadastrar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row top100">
                        <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                            <div className="row">
                                <div className="col-12">
                                    <span className="title">Cadastro</span>
                                    <p className="subTitle">Cadastrar Material/Serviço</p>
                                    <div className='mainDegLine'></div>
                                </div>
                                <div className="col-12 col-md-3 top10">
                                    <FieldText id="edtMaterial" name="edtMaterial" placeholder="Material/Serviço" />
                                </div>
                                {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                    <FieldText id="edtDescription" name="edtDescription" placeholder="Descrição" />
                                </div> */}
                                <div className="col-12 col-md-3 top10">
                                    {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> */}
                                    <div className="boxSelect">
                                        {
                                            <div id='boxSelectMeasurementId' className='borderGray'>
                                                {/* <label for='' id='' className="labelSelect">Unidade de Medida*</label><br /> */}
                                                <select name="measurement" id="measurementId" className="selectOptionsBox borderNone" onChange={(e) => setMeasurementID(e.target.value)}>
                                                    <option value='' className="selectOption blackFont">Unidade de Medida</option>
                                                    {
                                                        measurements.map((item, k) => {
                                                            return (
                                                                <option key={k} value={item.id} className="selectOption blackFont">{item.measurement}</option>
                                                            )
                                                            // return (
                                                            //     item.problem == problem ?
                                                            //         <option key={k} value={item.id} className="selectOption blackFont" selected>{item.problem}</option>
                                                            //         :
                                                            //         <option key={k} value={item.id} className="selectOption blackFont">{item.problem}</option>
                                                            // )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 top10">
                                    <FieldText id="edtMaterialPrice" name="edtMaterialPrice" placeholder="Preço" realType='priceType' />
                                </div>
                                <div className="col-12 col-sm-2 top10">
                                    <div onClick={e => registerMaterial()}>
                                        <ButtonRectangle label="Cadastrar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row top10 padding15">
                        <div className="col-12">
                            {
                                isShowTable &&
                                <TableGrid id="materialGridId" title="Relatório" subTitle="Gerenciar Materiais" columns={materialColumns} data={materialData} loadingFunction={setIsLoading} />
                            }
                        </div>
                    </div>

                    <button id="btnUpdateMaterialModal" data-toggle="modal" data-target='#updateMaterialModal' className='invisible btn mainGradBK whiteFont padding10 padding20Side delay'></button>
                    <div class="modal fade" id='updateMaterialModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar Material</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedMaterial &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 top10" onKeyDown={e => pressEnter(e)}>
                                                        <FieldText id="edtMaterialUpdate" name="edtMaterialUpdate" placeholder="Material" value={selectedMaterial ? selectedMaterial.name : ''} />
                                                    </div>
                                                    {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                                    <FieldText id="edtDescriptionUpdate" name="edtDescriptionUpdate" placeholder="Description" value={selectedMaterial ? selectedMaterial.description : ''} />
                                                </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 top10">
                                                        {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> */}
                                                        <div className="boxSelect">
                                                            {
                                                                <div id='boxSelectMeasurementId' className='borderGray'>
                                                                    {/* <label for='' id='' className="labelSelect">Unidade de Medida*</label><br /> */}
                                                                    <select name="measurement" id="measurementId" className="selectOptionsBox borderNone" onChange={(e) => setMeasurementID(e.target.value)}>
                                                                        <option value='' className="selectOption blackFont">Unidade de Medida</option>
                                                                        {
                                                                            measurements.map((item, k) => {
                                                                                return (
                                                                                    item.id == selectedMaterial.measurement.id ?
                                                                                        <option key={k} value={item.id} className="selectOption blackFont" selected>{item.measurement}</option>
                                                                                        :
                                                                                        <option key={k} value={item.id} className="selectOption blackFont">{item.measurement}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 top10">
                                                        <FieldText id="edtMaterialPriceUpdate" name="edtMaterialPriceUpdate" placeholder="Preço" realType='priceType' value={selectedMaterial ? getLenghtOfDefimalPrice(selectedMaterial.price) : ''} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 top10 toRight">
                                                        <div onClick={e => updateMaterial()}>
                                                            <ButtonRectangle label="Salvar" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="row top40">
                        <div className="col-12">
                            {/* {
                            materials && <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary mainColorBk"
                                data={materials}>
                                Download
                            </CSVLink>
                        } * /}
                        </div>
                        <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                            <table className="table table-hover">
                                <thead>
                                    {
                                        isShowTable &&
                                        materials &&
                                        <TableFilter
                                            rows={materials}
                                            onFilterUpdate={filterUpdated}
                                        >
                                            <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                                Código
                                            </th>
                                            <th filterkey="name">
                                                Material
                                            </th>
                                            {/* <th filterkey="description">
                                                Descrição
                                            </th> * /}
                                            <th filterkey="measurement.id">
                                                Unidade de medida
                                            </th>
                                            <th filterkey="price">
                                                Preço
                                            </th>
                                            <th></th>
                                        </TableFilter >
                                    }
                                </thead>
                                <tbody>
                                    {
                                        materials &&
                                        materials.map((item, key) => (
                                            <tr key={key}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                {/* <td>{item.description}</td> * /}
                                                <td>{item.measurement.measurement}</td>
                                                <td>R$ {getLenghtOfDefimalPrice(item.price)}</td>
                                                <td>
                                                    <button data-toggle="modal" data-target='#updateMaterialModal' className='btn mainGradBK whiteFont padding10 padding20Side delay' onClick={(e) => changeSelectedMaterial(item)}>Editar</button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div class="modal fade" id='updateMaterialModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialModal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="modalLongTitle">Editar Material</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {
                                            selectedMaterial &&
                                            <div className="row">
                                                <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                                    <FieldText id="edtMaterialUpdate" name="edtMaterialUpdate" placeholder="Material" value={selectedMaterial ? selectedMaterial.name : ''} />
                                                </div>
                                                {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}>
                                                    <FieldText id="edtDescriptionUpdate" name="edtDescriptionUpdate" placeholder="Description" value={selectedMaterial ? selectedMaterial.description : ''} />
                                                </div> * /}
                                                <div className="col-12 col-md-3 top10">
                                                    {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> * /}
                                                    <div className="boxSelect">
                                                        {
                                                            <div id='boxSelectMeasurementId' className='borderGray'>
                                                                {/* <label for='' id='' className="labelSelect">Unidade de Medida*</label><br /> * /}
                                                                <select name="measurement" id="measurementId" className="selectOptionsBox borderNone" onChange={(e) => setMeasurementID(e.target.value)}>
                                                                    <option value='' className="selectOption blackFont">Unidade de Medida</option>
                                                                    {
                                                                        measurements.map((item, k) => {
                                                                            return (
                                                                                item.id == selectedMaterial.measurement.id ?
                                                                                    <option key={k} value={item.id} className="selectOption blackFont" selected>{item.measurement}</option>
                                                                                    :
                                                                                    <option key={k} value={item.id} className="selectOption blackFont">{item.measurement}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3 top10">
                                                    <FieldText id="edtMaterialPriceUpdate" name="edtMaterialPriceUpdate" placeholder="Preço" realType='priceType' value={selectedMaterial ? getLenghtOfDefimalPrice(selectedMaterial.price) : ''} />
                                                </div>
                                                <div className="col-12 col-md-3 top10">
                                                    <div onClick={e => updateMaterial()}>
                                                        <ButtonRectangle label="Salvar" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div class="modal-footer">
                                        <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                        {/* <button type="button" class="btn btn-primary">Save changes</button> * /}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            }









            <div className="full">
                <div className='container padding100Top'>
                    <div className='row'>
                        <p className='title'>Relatório do uso de materiais</p>
                        <div className='mainDegLine'></div>
                    </div>
                    <div className='row'>
                        <div className="col-6 center">
                            {
                                materialsUsage && <div>
                                    {/* <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary margin5 mainColorBk"
                                data={materialsUsage}>
                                CSV
                            </CSVLink> */}
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn mainGradBK whiteFont padding20Side delay margin5"
                                        table="table-to-xls"
                                        filename="usoDeMateriaisDetalhes"
                                        sheet="materiais"
                                        buttonText="Download Detalhes" />
                                </div>
                            }
                            {
                                materialsTotalUsage && <div>
                                    {/* <CSVLink
                                filename='arquivo.csv'
                                className="btn btn-primary margin5 mainColorBk"
                                data={materialsUsage}>
                                CSV
                            </CSVLink> */}
                                    <ReactHTMLTableToExcel
                                        id="total-table-xls-button"
                                        className="btn mainGradBK whiteFont padding20Side delay margin5"
                                        table="table-to-xls-total"
                                        filename="usoDeMateriaisTotal"
                                        sheet="materiais"
                                        buttonText="Download Total" />
                                </div>
                            }
                        </div>
                        <div className='col-6'>
                            <div className="row">
                                <div className='row'>
                                    <p>Data de atendimento</p>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-md-3">
                                        <FieldText id="edtPeriodOne" name="edtPeriodOne" placeholder="Data" />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <FieldText id="edtPeriodTwo" name="edtPeriodTwo" placeholder="Data" />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div onClick={e => filterByDate()}>
                                            <ButtonRectangle label="Filtrar" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 top20">
                        <table id="table-to-xls" className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    isShowTable &&
                                    materialsUsage &&
                                    <TableFilter
                                        rows={materialsUsage}
                                        onFilterUpdate={filterUpdatedMaterialUsage}
                                    >
                                        <th filterkey="material" key="material" filterkey="material" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Material
                                        </th>
                                        <th filterkey="city">
                                            Cidade
                                        </th>
                                        <th filterkey="uf">
                                            UF
                                        </th>
                                        <th filterkey="finishDateBR">
                                            Data de conclusão
                                        </th>
                                        <th filterkey="quantity">
                                            Quantidade
                                        </th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    materialsUsage &&
                                    materialsUsage.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.material}</td>
                                            <td>{item.city}</td>
                                            <td>{item.uf}</td>
                                            <td>{item.finishDateBR}</td>
                                            <td>{item.quantity}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="invisible col-12 text-center bottomBlueBorder padding100Top">
                        <p className="title">Relatório total do uso de materiais</p>
                    </div>
                    <div className=" invisible col-12">
                        <table id="table-to-xls-total" className="table boxShadow table-hover borderRadius10">
                            <thead>
                                {
                                    isShowTable &&
                                    materialsUsage &&
                                    <TableFilter
                                        rows={materialsUsage}
                                        onFilterUpdate={filterUpdatedMaterialUsage}
                                    >
                                        <th filterkey="material" key="material" filterkey="material" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Material
                                        </th>
                                        <th filterkey="city">
                                            Cidade
                                        </th>
                                        <th filterkey="uf">
                                            UF
                                        </th>
                                        <th filterkey="quantity">
                                            Quantidade
                                        </th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    materialsTotalUsage &&
                                    materialsTotalUsage.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.material}</td>
                                            <td>{item.city}</td>
                                            <td>{item.uf}</td>
                                            <td>{item.quantity}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <Footer />
        </div >
    )
}

export default Material