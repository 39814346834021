import './styles.css'

const OutlineField = ({ id = '', label = '', placeholder = '', value = null, onChangeFunc = null }) => {
    return (
        <div className="row">
            <div className="col-12">
                <span>{label}</span>
            </div>
            <div className="col-12">
                {
                    value != null ?
                        <input type="text" id={id} value={value} class="borderOutlineBtn use100 padding8" placeholder={placeholder} onChange={onChangeFunc} />
                        :
                        <input type="text" id={id} class="borderOutlineBtn use100 padding8" placeholder={placeholder} onChange={onChangeFunc} />

                }
                {/* <input type="text" id={id} class="borderOutline use100 padding8 mainColor" placeholder={placeholder} onChange={onChangeFunc} /> */}
            </div>
        </div>
    )
}

export default OutlineField