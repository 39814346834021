import React, { Component } from 'react'
import './styles.css'

export default class SelectBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lst: props.lst
        }
    }

    componentDidMount() {
        this.setState({
            lst: this.props.lst
        })
    }

    setFocus = (elementId) => {
        document.getElementById(elementId).placeholder = ""
        document.getElementById('label_' + elementId).classList.remove('labelFloatingSelect')
        document.getElementById('label_' + elementId).classList.add('floatingSelectOnFocus')
    }

    lostFocus = (elementId) => {
        document.getElementById('label_' + elementId).classList.remove('floatingSelectOnFocus')
        document.getElementById('label_' + elementId).classList.add('labelFloatingSelect')
    }

    render() {
        return (
            <div className="boxSelect">
                <label for={this.props.id} id={'label_' + this.props.id} className="labelSelect">{this.props.label}</label><br />
                <select name="city" id="cityId" className="selectOptionsBox">
                    <option value="volvo" className="selectOption" selected></option>
                    {
                        this.state.lst.length > 0 ? (
                            this.state.lst.map((item, i) => {
                                return this.props.type == 'city' ?
                                    item.localidade == this.props.loc ?
                                        <option value={item.id} className="selectOption blackFont" selected>{item.name}</option>
                                        :
                                        <option value={item.id} className="selectOption blackFont">{item.name}</option>
                                    :
                                    <option value={item.id} className="selectOption blackFont">{item.name}</option>
                            })
                        ) : null
                    }

                    {/* <option value="volvo" className="selectOption">Ascurra</option>
                    <option value="saab" className="selectOption">Rodeio</option>
                    <option value="opel" className="selectOption">Rio do Sul</option>
                    <option value="audi" className="selectOption">Indaial</option>
                    <option value="audi" className="selectOption">Timbó</option> */}
                </select>
            </div>
        )
    }

}