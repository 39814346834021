import { create } from 'apisauce'

const rest = create({
    //baseURL: 'http://127.0.0.1:8081',
    baseURL: 'http://vps31302.publiccloud.com.br:8081',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    }
})

//Para tratativa de erros
//Executa depois de receber o resultado e antes de enviar para o response no metodo que o chamou
rest.addResponseTransform(response => {//Isso é um Middleware para resposta da API
    if (!response.ok) throw response //Assim vai para um cath que temos no method que chama o rest
})

//Middleware na requisição
rest.addAsyncRequestTransform(request => async () => {
    //Recupera o token
    // const token = await localStorage.getItem('@lighthouse_jjeletrica:token')
    // if (token)
    //     request.headers['Authorization'] = `Bearer ${token}`
    request.headers['param_token'] = 'tokenlh'
})

export default rest
