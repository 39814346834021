import React, { Component } from 'react';
import './styles.css'

export default class FieldText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: ''
    }
  }

  focus = (elementId) => {
    try {
      document.getElementById('box' + elementId).classList.add('inputBoxOnFocus')
    } catch (err) {
    }
  }

  lostFocus = (elementId) => {
    try {
      document.getElementById('box' + elementId).classList.remove('inputBoxOnFocus')
    } catch (err) {
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.state.inputValue) {
      await this.setState({
        inputValue: nextProps.value
      })
    }
  }

  updateInputValue = (evt) => {
    this.setState({
      inputValue: evt.target.value
    })
  }

  async componentDidMount() {
    this.setState({
      inputValue: this.props.value
    })
  }

  formatterValue = (e) => {
    let c = e.target
    if (this.props.realType == 'dateType') {
      let v = c.value.replace(/[^0-9.]/g, "")

      if (v.length == 2) {//ex: 2 = dia 21
        c.value = v.substr(0, 2) + '/'
      } else if (v.length == 3) { //ex: 4 = dia 21/0
        c.value = v.substr(0, 2) + '/' + v.substr(2, 1)
      } else if (v.length == 4) { //ex: 5 = dia 21/04
        c.value = v.substr(0, 2) + '/' + v.substr(2, 2) + '/'
      } else if (v.length > 4) { //ex: 6+ = dia 21/04/2020
        c.value = v.substr(0, 2) + '/' + v.substr(2, 2) + '/' + v.substr(4, 4)
      } else {
        /*
          Se digitar apenas letra por exemplo, cai nesse bloco,
          pois substituimos tudo que não é número por "",
          assim o length é 0 e não cai nas condições a cima
        */
        if (v.length == 0)//Ese if é para não apagar o valor caso tenha só um número informado
          c.value = ''
      }
    } else if (this.props.realType == 'hourType') {
      let v = c.value.replace(/[^0-9.]/g, "")

      if (v.length == 2) {//ex: 2 = dia 21
        c.value = v.substr(0, 2) + ':'
      } else if (v.length == 3) { //ex: 4 = dia 21/0
        c.value = v.substr(0, 2) + ':' + v.substr(2, 1)
      } else if (v.length == 4) { //ex: 5 = dia 21/04
        c.value = v.substr(0, 2) + ':' + v.substr(2, 2) + ':'
      } else if (v.length > 4) { //ex: 6+ = dia 21/04/2020
        c.value = v.substr(0, 2) + ':' + v.substr(2, 2) + ':' + v.substr(4, 2)
      } else {
        /*
          Se digitar apenas letra por exemplo, cai nesse bloco,
          pois substituimos tudo que não é número por "",
          assim o length é 0 e não cai nas condições a cima
        */
        if (v.length == 0)//Ese if é para não apagar o valor caso tenha só um número informado
          c.value = ''
      }
    } else if (this.props.realType == 'priceType') {
      try {
        let v = c.value
        let integer = v.split(',')[0];

        v = v.replace(/\D/, "");
        v = v.replace(/^[0]+/, "");

        if (v.length <= 2 || !integer) {
          if (v.length === 1) v = '0,0' + v;
          if (v.length === 2) v = '0,' + v;
          c.value = v
        } else {
          v = v.replace(/^(\d{1,})(\d{2})$/, "$1,$2");
          c.value = v
        }
      } catch (e) { }
    }
  }

  render() {
    return (
      <div className="borderOutline use100 padding8 delay" id={`box${this.props.id}`}>
        <span className="iconBoxField">
          <i className={this.props.icon}></i>
        </span>
        <input readOnly={this.props.readOnly} type={this.props.type ? this.props.type : "text"} id={this.props.id} name={this.props.name} placeholder={this.props.placeholder} defaultValue={this.state.inputValue} className="input" onFocus={e => this.focus(this.props.id)} onBlur={e => this.lostFocus(this.props.id)} onChange={this.updateInputValue, this.props.triggerParentUpdate} onKeyUp={e => this.formatterValue(e)} />
      </div>
    )
  }
}

/*
import React from 'react';
import './styles.css'

function focus(elementId) {
  try {
    document.getElementById('box'+elementId).classList.add('inputBoxOnFocus')
  } catch (err) {
    console.log('e...logout')
    console.log(err)
  }
}

function lostFocus(elementId) {
  try {
    document.getElementById('box'+elementId).classList.remove('inputBoxOnFocus')
  } catch (err) {
    console.log('e...logout')
    console.log(err)
  }
}

function FieldText(props) {
  return (
    <div className="inputBox center padding03 delay" id={`box${props.id}`}>
      <span className="iconBox">
        <i className={props.icon}></i>
      </span>
      <input type="text" id={props.id} name={props.name} placeholder={props.placeholder} value={props.value} className="input" onFocus={e => focus(props.id)} onBlur={e => lostFocus(props.id)} />
    </div>
  );
}
export default FieldText;
*/
