const ReactagleButton = ({ id = '', text, func = null }) => {
    return (
        <>
            {
                id != '' ? //add a função com addEventListener
                    <button id={id} type="button" class="btn mainGradBK whiteFont padding20Side delay">{text}</button>
                    :
                    <button type="button" class="btn mainGradBK whiteFont padding20Side delay" onClick={func}>{text}</button>
            }

        </>
    )
}

export default ReactagleButton