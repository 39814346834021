import React, { useEffect, useState } from 'react'
import Loading from '../../components/loadingBar'
import rest from '../../service/restAuth'
import Footer from '../../components/footer'
import { CSVLink, CSVDownload } from 'react-csv'
import LoadingBar from '../../components/loadingBar'
import MenuBar from '../../components/menuBar'
import TableFilter from 'react-table-filter'
import { Link } from 'react-router-dom'
import OverMessage from '../../components/overMessage'
import FieldText from '../../components/fieldText'

import * as saver from 'file-saver'

import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'

import './styles.css'
const Task = (props) => {
    const [isCanReallyDeleteHelpDesk, setIsCanReallyDeleteHelpDesk] = useState(false)
    const [textDeleteBtn, setTextDeleteBtn] = useState('Apagar chamado')
    const [isLoading, setIsLoading] = useState(false)
    const [userLevel, setUserLevel] = useState()
    const [task, setTask] = useState()
    const [taskID, setTaskID] = useState()
    const [materials, setMaterials] = useState()
    const [selectedMaterials, setSelectedMaterials] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const [photos, setPhotos] = useState([])
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findTaskByID = async () => {
        try {
            setIsLoading(true)
            const response = await rest.get(`/helpdesk/v1/findbyid/${taskID}`)
            setTask(response.data)
            setSelectedMaterials(response.data.materialHelpDesk)//Lista de materiais utilizados
            document.getElementById('edtTaskDescription').value = response.data.technicalDescription
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const finishHelpDesk = async (photos = [], isFinish = true) => {
        try {
            setIsLoading(true)

            if (isFinish) {
                //Encerra o chamado
                const response = await rest.post(`/helpdesk/v1/finish/${task.id}`)

                //Add os materiais no chamado
                const responseMaterial = await rest.post(`/helpdesk/v1/materialregister`, {
                    id: task.id,
                    technicalDescription: document.getElementById('edtTaskDescription').value,
                    materialHelpDesk: selectedMaterials,
                    photos: photos
                })
            } else { //Atualizar chamados
                //Remove os materias vinculados ao chamado
                // const response = await rest.post(`/helpdesk/v1/two/removeMaterials/${task.id}`)

                //Add os materiais no chamado
                // if (response.status == 200) {
                const responseMaterial = await rest.post(`/helpdesk/v1/materialregister`, {
                    id: task.id,
                    technicalDescription: document.getElementById('edtTaskDescription').value,
                    materialHelpDesk: selectedMaterials,
                    photos: photos
                })
                removeSelectedFile()
            }

            findTaskByID()
            setOverMessage({
                show: true,
                message: 'Chamado encerrado com sucesso',
                type: 'success'
            })
            setIsLoading(false)
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 3000)
        } catch (e) {
            //const response = await rest.post(`/helpdesk/v1/two/finish/${task.id}`)
            setOverMessage({
                show: true,
                message: 'Falha ao encerrar chamado',
                type: 'error'
            })
            setIsLoading(false)
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 3000)
        }
    }

    const findMaterials = async () => {
        const response = await rest.get('/material/v1/findAll')
        setMaterials(response.data)
    }

    const removeMaterial = async (materialID, materialName) => {
        let newSelectedMaterialLst = []
        if (selectedMaterials.length > 0) {
            let find = false
            for (let i = 0; i < selectedMaterials.length; i++) {
                const element = selectedMaterials[i]
                if (element.materialID != materialID) {
                    newSelectedMaterialLst.push(element)
                }

                if (i + 1 == selectedMaterials.length) {
                    setSelectedMaterials(newSelectedMaterialLst)
                }
            }
        }
    }

    const addMaterial = async (materialID, materialName) => {
        let newSelectedMaterialLst = []
        if (selectedMaterials.length > 0) {
            let find = false
            for (let i = 0; i < selectedMaterials.length; i++) {
                const element = selectedMaterials[i]

                if (element.materialID == materialID) {
                    find = true
                    newSelectedMaterialLst.push({
                        helpDeskID: task.id,
                        materialID: materialID,
                        material: {
                            id: materialID,
                            name: element.material.name
                        },
                        quantity: element.quantity + 1
                    })
                    // newSelectedMaterialLst.push({
                    //     helDesk: {
                    //         id: task.id
                    //     },
                    //     material: {
                    //         id: materialID,
                    //         name: materialName
                    //     },
                    //     quantity: element.quantity + 1
                    // })
                } else {
                    newSelectedMaterialLst.push(element)
                }

                //Se executou pela o loop pela ultima vez
                if (i + 1 == selectedMaterials.length) {
                    if (!find) {
                        // newSelectedMaterialLst.push({
                        //     helDesk: {
                        //         id: task.id
                        //     },
                        //     material: {
                        //         id: materialID,
                        //         name: materialName
                        //     },
                        //     quantity: 1
                        // })

                        newSelectedMaterialLst.push({
                            helpDeskID: task.id,
                            materialID: materialID,
                            material: {
                                id: materialID,
                                name: materialName
                            },
                            quantity: 1
                        })
                        // }
                    }
                    setSelectedMaterials(newSelectedMaterialLst)
                }
            }
            setSelectedMaterials(newSelectedMaterialLst)
        } else {
            // newSelectedMaterialLst.push(
            //     {
            //         helDesk: {
            //             id: task.id
            //         },
            //         material: {
            //             id: materialID,
            //             name: materialName
            //         },
            //         quantity: 1
            //     }
            // )
            newSelectedMaterialLst.push({
                helpDeskID: task.id,
                materialID: materialID,
                material: {
                    id: materialID,
                    name: materialName
                },
                quantity: 1
            })
            setSelectedMaterials(newSelectedMaterialLst)
        }
    }

    const savePhotos = async (isFinish = true) => {
        const files = document.getElementById('files')
        const server = "http://phpserver.protocolosjjeletrica.com.br/uploadFiles.php";
        const formData = new FormData()

        setIsLoading(true)

        let photos = []
        if (files.files[0] != null) {
            for (let i = 0; i < files.files.length; i++) {
                let file = files.files[i]
                let actualDate = new Date()
                let dateForName = ((actualDate.getDate() + '').length > 1 ? actualDate.getDate() + '_' : '0' + actualDate.getDate() + '_') +
                    (((actualDate.getMonth() + 1) + '').length > 1 ? (actualDate.getMonth() + 1) : '0' + (actualDate.getMonth() + 1) + '_') +
                    actualDate.getFullYear()

                let timeForName = ((actualDate.getHours() + '').length > 1 ? actualDate.getHours() + '_' : '0' + actualDate.getHours() + '_') +
                    ((actualDate.getMinutes() + '').length > 1 ? actualDate.getMinutes() + '_' : '0' + actualDate.getMinutes() + '_') +
                    ((actualDate.getSeconds() + '').length > 1 ? actualDate.getSeconds() : '0' + actualDate.getSeconds())

                photos.push({
                    'path': 'http://phpserver.protocolosjjeletrica.com.br/uploadLhFiles/',
                    'name': (dateForName + '_' + timeForName + '_' + i + (file.name.substring(file.name.lastIndexOf('.'), file.name.length)).toLowerCase()),
                    'photoOrigin': 1
                })
                formData.append('file' + i, file)
                formData.append('newName' + i, dateForName + '_' + timeForName + '_' + i)
                if (i + 1 == files.files.length) {
                    fetch(server, {
                        method: 'post',
                        body: formData
                    }).then((res) => {
                        setIsLoading(false)
                        setPhotos(photos)
                        finishHelpDesk(photos, isFinish)
                    }).catch((error) => {
                        setIsLoading(false)
                    })
                }
            }
        } else {
            finishHelpDesk([], isFinish)
        }
    }

    const selectImage = (e) => {
        setSelectedFile(e.target.files[0])
        setFileName(e.target.files[0].name)
    }

    // const downloadPhoto = (url) => {
    //     console.log(url)
    //     saver.saveAs(url, 'oi.png')
    // }

    const downloadPhoto = async (url, filename) => {
        // console.log(url)
        // let blob = await fetch(url, { mode: 'no-cors' }).then((r) => {
        //     console.log('blob...blob...blob...no-cors')
        //     console.log(r.blob())
        //     var pom = document.createElement('a');
        //     pom.setAttribute('href', 'data:image/jpeg;base64,' + encodeURIComponent(r.blob()));
        //     pom.setAttribute('download', 'oi.jpg');

        //     if (document.createEvent) {
        //         var event = document.createEvent('MouseEvents');
        //         event.initEvent('click', true, true);
        //         pom.dispatchEvent(event);
        //     }
        //     else {
        //         pom.click();
        //     }
        // })


        // fetch(url, {
        //     headers: {
        //         "Access-Control-Allow-Origin": "*"
        //     }
        // })
        //     .then(function (t) {
        //         return t.blob().then((b) => {
        //             var a = document.createElement("a");
        //             a.href = URL.createObjectURL(b);
        //             a.setAttribute("download", filename);
        //             a.click();
        //         }
        //         )
        //     })
    }

    const removeSelectedFile = () => {
        setSelectedFile(null)
        document.getElementById("files").value = ''
    }


    const isValidUser = async () => {
        const userFromStorage = await localStorage.getItem('@lighthouse_jjeletrica:user')
        const user = JSON.parse(userFromStorage)
        // const user = localStorage.getItem('@lighthouse_jjeletrica:user')
        if (user) {
            setUserLevel(user.level)
        }
    }

    const pdfGenerator = () => {
        setIsLoading(true)
        const doc = new jsPDF();


        doc.text("------------- Chamado -------------", 10, 10)
        doc.text("Protocolo: " + task.protocol, 10, 20)
        doc.text("Problema: " + task.problem.problem, 10, 30)
        doc.text("Cliente: " + task.client.name, 10, 40)
        doc.text("Contato: " + task.client.contacts[0].contact, 10, 50)
        doc.text("Descrição: " + task.description, 10, 60)
        doc.text("Data do Fechamento: " + task.finishDateBR, 10, 70)
        doc.text("Status: " + task.statusHelpDesk.status, 10, 80)

        doc.text("------------- Endereço -------------", 100, 10)
        doc.text("Cidade: " + task.address.city.name, 100, 20)
        doc.text("Bairro: " + task.address.neighborhood, 100, 30)
        doc.text("Rua: " + task.address.street, 100, 40)
        doc.text("Ponto de referência: " + task.address.referencePoint, 100, 50)
        doc.text("CEP: " + task.address.zipCode, 100, 60)
        doc.text("Complemento: " + task.address.complement, 100, 70)


        doc.text("------------- Materiais utilizados -------------", 10, 90)
        let px = 100
        selectedMaterials.forEach((item) => {
            doc.text(item.material.name + ' - ' + item.quantity, 10, px)
            px += 10
        })

        let i = 1
        task.photos.forEach((item) => {
            if (i == 1) {
                doc.addPage()
                i++
            }
            doc.text("URL: " + item.url, 5, 5)
            doc.addImage(item.url, 'JPEG', 15, 15, 180, 180)
            doc.addPage()
        })

        doc.save(task.protocol + ".pdf")
        setIsLoading(false)
    }

    const domPDF = () => {
        // const server = 'http://localhost/gerarpdf/pdfGenerator.php'
        // const server = '../../php/pdfGenerator.php'
        // const formData = new FormData()
        // let page = document.getElementById('page')
        // formData.append('page', page)
        // console.log(page)
        // fetch(server, {
        //     method: 'post',
        //     body: formData
        // }).then((res) => {
        //     console.log('suc')
        //     console.log(res)
        //     setIsLoading(false)
        // }).catch((error) => {
        //     console.log('cat')
        //     setIsLoading(false)
        // })
    }

    const deleteCurrentHelpDesk = async () => {
        console.log(isCanReallyDeleteHelpDesk)
        if(isCanReallyDeleteHelpDesk){
           const response = await rest.post(`/helpdesk/v1/deleteHelpDeskById/${taskID}`)
           props.history.push('/chamados')
        } else {
            setIsCanReallyDeleteHelpDesk(true)
            setTextDeleteBtn('Sim! Apagar chamado')
            document.getElementById('deleteBtn').classList.remove('mainGradBK')
            document.getElementById('deleteBtn').classList.add('errorBk')
        }
    }

    useEffect(() => {
        let helpDeskID = props.location.state.helpDeskID
        if (helpDeskID == 0 || helpDeskID == undefined || helpDeskID == null) {
            props.history.push('/chamados')
        } else {
            setTaskID(helpDeskID)
            isValidUser()
        }
    }, [])

    useEffect(() => {
        if (taskID > 0) {
            findTaskByID()
            findMaterials()
        }
    }, [taskID])

    return (
        <div id='page'>
            {
                isLoading && <LoadingBar />
            }
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div id="top">
                <MenuBar />
            </div>

            <div className="full padding100Top container">
                {/* <button className="btn btn-primary margin5 mainColorBk" onClick={() => domPDF()}>Download PDF</button> */}
                <div className="row top40">
                    <div className="col-12">
                        <div className='secondColorBK smallShadow imgCardBox radius12 use100 padding30'>
                            {
                                task &&
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <div className='col-12'><div className='labelTaskTitle padding08'>Chamado</div><div className='line'></div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Protocolo:&nbsp;&nbsp;</div> <div>{task.protocol}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Problema:&nbsp;&nbsp;</div> <div>{task.problem.problem}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Cliente:&nbsp;&nbsp;</div> <div>{task.client.name}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Contato:&nbsp;&nbsp;</div> {task.client.contacts.map((item, k) => (<div key={k}>{k == 0 ? item.contact : ' -- ' + item.contact}</div>))}</div>
                                        <div className='col-12 inline'><div className='labelTask'>Descrição:&nbsp;&nbsp;</div> <div>{task.description}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Data do Abertura:&nbsp;&nbsp;</div> <div>{task.openedDateBR}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Data do Fechamento:&nbsp;&nbsp;</div> <div>{task.finishDateBR}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Status:&nbsp;&nbsp;</div> <div>{task.statusHelpDesk.status}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Encerrado por:&nbsp;&nbsp;</div> <div>{task.finishedUser.username}</div></div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className='col-12'><div className='inline padding03'><div className='labelTaskTitle'>Endereço</div> <div className='toRight1 w100'>
                                            {
                                                userLevel == 3 &&
                                                <div>
                                                        <button id='deleteBtn' className='btn mainGradBK whiteFont btn-block padding10 padding20Side delay' onClick={() => deleteCurrentHelpDesk()}>{textDeleteBtn}</button>
                                                </div>
                                            }
                                            {
                                                userLevel == 3 &&
                                                <div>
                                                    {
                                                        task.statusHelpDesk.id != 3 ?
                                                            <button className='btn mainGradBK whiteFont btn-block padding10 padding20Side delay' onClick={() => savePhotos(true)}>Concluir chamado</button>
                                                            :
                                                            <>
                                                                {  /* statusHelpDesk = 3 e userLevel = 3 -> chamado concluído e usuário N3 */
                                                                    userLevel == 3 ?
                                                                        <button className='btn mainGradBK whiteFont btn-block padding10 padding20Side delay' onClick={() => savePhotos(false)}>Atualizar</button>
                                                                        :
                                                                        <button className='btn mainGradBK whiteFont btn-block padding10 padding20Side delay disable'>Concluir chamado</button>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            }
                                        </div></div><div className='line'></div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Cidade:&nbsp;&nbsp;</div> <div>{task.address.city.name}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Bairro:&nbsp;&nbsp;</div> <div>{task.address.neighborhood}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Rua:&nbsp;&nbsp;</div> <div>{task.address.street}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Ponto de referência:&nbsp;&nbsp;</div> <div>{task.address.referencePoint}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>CEP:&nbsp;&nbsp;</div> <div>{task.address.zipCode}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Complemento:&nbsp;&nbsp;</div> <div>{task.address.complement}</div></div>
                                        <div className='col-12 inline'><div className='labelTask'>Lote:&nbsp;&nbsp;</div> <div>{task.address.lot}</div></div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='col-12'><div className='labelTaskTitle padding08'>Materiais</div><div className='line'></div></div>
                                        <div className='col-12'>
                                            {
                                                selectedMaterials &&
                                                selectedMaterials.map((item, k) => (
                                                    <div key={k} className="row">
                                                        <div className="col-12 padding04">
                                                            {
                                                                //Se o chamado estiver concluído e for usuário N3 pode alterar os materiais
                                                                userLevel == 3 ?
                                                                    task.statusHelpDesk.id == 3 ?
                                                                        userLevel == 3 ?
                                                                            <>
                                                                                {<button className='btn errorBk margin5' onClick={() => removeMaterial(item.materialID, item.material.name)}>X</button>}
                                                                                Material: {item.material.name} - Quantidade Usada: {item.quantity}
                                                                            </>
                                                                            :
                                                                            <> {/* chamado concluído mas con usuário N2 - pode visualizar mas não pode alterar */}
                                                                                {<button className='btn errorBk margin5 disable'>X</button>}
                                                                                Material: {item.material.name} - Quantidade Usada: {item.quantity}
                                                                            </>
                                                                        :
                                                                        <>
                                                                            {<button className='btn errorBk margin5' onClick={() => removeMaterial(item.materialID, item.material.name)}>X</button>}
                                                                            Material: {item.material.name} - Quantidade Usada: {item.quantity}
                                                                        </>
                                                                    :
                                                                    <> {/* chamado concluído mas con usuário N2 - pode visualizar mas não pode alterar */}
                                                                        {/* {<button className='btn errorBk margin5 disable'>X</button>} */}
                                                                        Material: {item.material.name} - Quantidade Usada: {item.quantity}
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='row'>
                                            {
                                                userLevel == 3 &&
                                                materials &&
                                                materials.map((item, k) => (
                                                    <div className='col-12 col-sm-6 inline padding03' key={k}>
                                                        {
                                                            //Se o chamado estiver concluído e for usuário N3 pode alterar os materiais
                                                            task.statusHelpDesk.id == 3 ?
                                                                userLevel == 3 ?
                                                                    <>
                                                                        {<button className='btn btn-primary mainColorBk' onClick={() => addMaterial(item.id, item.name)}>+</button>} <div className='padding10'>{item.name}</div>
                                                                    </>
                                                                    :
                                                                    <> {/* chamado concluído mas con usuário N2 - pode visualizar mas não pode alterar */}
                                                                        {<button className='btn btn-primary mainColorBk disable'>+</button>} <div className='padding10'>{item.name}</div>
                                                                    </>
                                                                :
                                                                <>
                                                                    {<button className='btn btn-primary mainColorBk' onClick={() => addMaterial(item.id, item.name)}>+</button>} <div className='padding10'>{item.name}</div>
                                                                </>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-8 top10">
                                                <h5>Descrição do atendimento</h5>
                                                {
                                                    userLevel == 3 ?
                                                        task.statusHelpDesk.id != 3 ?
                                                            <FieldText id="edtTaskDescription" name="edtTaskDescription" placeholder="Descrição do atendimento" />
                                                            :
                                                            <FieldText id="edtTaskDescription" name="edtTaskDescription" placeholder="Descrição do atendimento" readOnly />
                                                        :
                                                        <FieldText id="edtTaskDescription" name="edtTaskDescription" placeholder="Descrição do atendimento" readOnly />
                                                }
                                            </div>
                                            {
                                                userLevel == 3 &&
                                                <div className="col-12 col-md-4 top40">
                                                    <div className="fileUpload whiteFont center delay2">
                                                        {
                                                            task.statusHelpDesk.id != 3 ?
                                                                <label for="files" className="fileUploadInside">
                                                                    <h5>Adicionar Foto</h5>
                                                                </label>
                                                                :
                                                                <>
                                                                    {
                                                                        userLevel == 3 ?
                                                                            <label for="files" className="fileUploadInside">
                                                                                <h5>Adicionar Foto</h5>
                                                                            </label>
                                                                            :
                                                                            <label for="files" className="fileUploadInside disable">
                                                                                <h5>Adicionar Foto</h5>
                                                                            </label>
                                                                    }
                                                                </>
                                                        }
                                                        {/* <input type="file" id="files" accept='image/*' className="fileUpload" name="files" multiple onChange={(f) => addPhotos(f)} /> */}
                                                        {
                                                            task.statusHelpDesk.id != 3 ?
                                                                <input type="file" id="files" accept="image/*" className="fileUpload" name="files" onChange={(e) => selectImage(e)} />
                                                                :
                                                                userLevel == 3 ?
                                                                    <input type="file" id="files" accept="image/*" className="fileUpload" name="files" onChange={(e) => selectImage(e)} />
                                                                    :
                                                                    null
                                                        }
                                                    </div>
                                                    {
                                                        selectedFile &&
                                                        <div for="" className="row">
                                                            <input className="borderNone" type="text" value={fileName} readOnly /><span className="roundedOutlineRedBtn center delay" onClick={(e) => removeSelectedFile()}>x</span>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 top40">
                        <div className="row top40 bottom30">
                            {
                                task &&
                                task.photos &&
                                task.photos.map((item, k) => (
                                    <div key={k} className="col-12 col-sm-6 col-md-4 col-lg-3 top10 padding5Side boxShadowHover">
                                        <button type="button" class="btn" data-toggle="modal" data-target={`#imageModal${item.id}`}>
                                            {
                                                item.path == "http://vps31302.publiccloud.com.br/" ?
                                                <img src={`http://vps31302.publiccloud.com.br:8081/helpdesk/v1/findfile/${item.name}`} class="rounded photo"></img>
                                                :
                                                <img src={item.url} class="rounded photo"></img>
                                            }
                                        </button>
                                        <div class="modal fade" id={`imageModal${item.id}`} tabindex="-1" role="dialog" aria-labelledby="modalLongTitle" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="modalLongTitle">Foto</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        {/* <img src={item.url} class="rounded w100"></img> */}
                                                        {
                                                            item.path == "http://vps31302.publiccloud.com.br/" ?
                                                            <img src={`http://vps31302.publiccloud.com.br:8081/helpdesk/v1/findfile/${item.name}`} class="rounded w100"></img>
                                                            :
                                                            <img src={item.url} class="rounded w100"></img>
                                                        }
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                                        {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default Task