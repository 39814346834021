import React, { Component } from 'react'
import './styles.css'
import restZipCode from '../../service/restApiZipCode'
import { setValue } from 'inputmask'

export default class FloatingField extends Component {
    constructor(props) {
        super(props)
    }

    setFocus = (elementId) => {
        document.getElementById(elementId).placeholder = ""
        document.getElementById(elementId).classList.remove('floatingInputRequired')
        document.getElementById('label_' + elementId).classList.remove('labelFloating')
        document.getElementById('label_' + elementId).classList.add('floatingInputOnFocus')
    }

    lostFocus = (elementId) => {
        if (document.getElementById(elementId).value == '') {
            document.getElementById('label_' + elementId).classList.remove('floatingInputOnFocus')
            document.getElementById('label_' + elementId).classList.add('labelFloating')
        }
    }

    findInfosByZipCode = async () => {
        try {
            let zipCode = (document.getElementById(this.props.id).value).replace('-', '')
            if (zipCode != '') {//Se o usuáiro clicar no CEP, mas não informar nada... não faz a consulta
                try {
                    this.props.setIsLoading(true)
                    const response = await restZipCode.get(`/${(zipCode)}/json`)
                    if (response.status == 200 && !response.data.erro) {
                        //Só exibe as informações do CEP se for de uma cidade válida
                        for (let i = 0; i < this.props.lst.length; i++) {
                            const element = this.props.lst[i]
                            if (response.data.localidade == element.name) {
                                this.props.setInfosFromZipCode(response.data)
                                break
                            } else {
                                //Se esse for o ultimo elemento... já verificamos que o CEP informado não está na lista de cidades válidas
                                if ((i + 1) == this.props.lst.length) {
                                    this.props.setInfosFromZipCode({
                                        status: 404,
                                        currentZipCode: '',
                                        bairro: '',
                                        localidade: '',
                                        logradouro: '',
                                        uf: ''
                                    })
                                }
                            }
                        }
                    } else {
                        this.props.setInfosFromZipCode({
                            status: 404,
                            currentZipCode: '',
                            bairro: '',
                            localidade: '',
                            logradouro: '',
                            uf: ''
                        })
                    }
                    this.props.setIsLoading(false)
                } catch (e) {
                    this.props.setInfosFromZipCode({
                        status: 404,
                        currentZipCode: '',
                        bairro: '',
                        localidade: '',
                        logradouro: '',
                        uf: ''
                    })
                    this.props.setIsLoading(false)
                }
            }
            // await localStorage.setItem('@tasks:infosFromZip', JSON.stringify(response.data))
        } catch (err) {
            // await localStorage.setItem('@tasks:infosFromZip', '')
            // await this.setState({
            //     isLoading: false,
            //     showAlertError: true,
            //     errorMsg: 'Falha ao consultar clientes',
            //     pageNumber: 0,
            //     maxPage: 0
            // })
        }
    }

    componentDidMount() {
        try {
            setInterval(() => {
                if (document.getElementById(this.props.id)) {
                    if (document.getElementById(this.props.id).value != '') {
                        this.setFocus(this.props.id)
                    }
                }
            }, 500);
        } catch (error) {

        }
    }

    focusAndCode = (id) => {
        this.lostFocus(id)
        this.findInfosByZipCode()
    }

    render() {
        return (
            <div className="boxFloatingInput">
                <label for={this.props.id} id={'label_' + this.props.id} className="labelFloating">{this.props.label}&nbsp;{this.props.required ? "*" : ""}</label><br />
                {
                    this.props.type == 'zipcode' ?
                        <div>
                            <input id={this.props.id} className="floatingInput" type="text" placeholder="" onFocus={e => this.setFocus(this.props.id)} onBlur={e => this.focusAndCode(this.props.id)} readOnly={this.props.readOnly} />
                            {/* <div className="btBlueSmall w10 center" onClick={e => this.findInfosByZipCode()}>
                                <i className="pi pi-search"></i>
                            </div> */}
                        </div>
                        :
                        <input id={this.props.id} className="floatingInput" value={this.props.value} type="text" placeholder="" onFocus={e => this.setFocus(this.props.id)} onBlur={e => this.lostFocus(this.props.id)} onChange={(e) => this.props.setValue(e.target.value)} readOnly={this.props.readOnly} />
                }
            </div>
        )
    }

}