import React, { useState, useEffect } from 'react'

//components
import ReactagleButton from '../../components/reactagleButton'
import OutlineField from '../../components/outlineField'

//GridJS
import { h, Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

//xls
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

const TableGrid = ({ id = '', title = '', subTitle = '', columns = [], data = [], loadingFunction = null }) => {
    const [isShowFilters, setIsShowFilters] = useState(false)
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [dataSet, setDataSet] = useState([])
    const [gridRowLimit, setGridRowLimit] = useState(10)
    const [grid, setGrid] = useState(null)
    const [isFirsRenderGrid, setIsFirsRenderGrid] = useState(true)
    const [isDownloadData, setIsDownloadData] = useState(false)
    const [isCreateTableForDownload, setIsCreateTableForDownload] = useState(false)

    useEffect(() => {
        if (data) {
            if (data.length > 0) {
                setDataSet(data)
            } else {
                setDataSet([])
            }
        } else {
            setDataSet([])
        }
    }, [])

    useEffect(() => {
        setIsShowGrid(true)
        const asyncRenderNewData = async () => {
            if (grid != null) {
                await gridConf()
                await asyncRenderGrid()
                await gridUpdate()
                // await addGridID()
            }
        }
        asyncRenderNewData()
    }, [dataSet])

    const gridConf = async () => {
        if (dataSet.length > 0 && columns.length > 0) {
            // if (columns.length > 0) {
            // const grid = new Grid({
            setGrid(new Grid({
                columns: columns,
                pagination: {
                    limit: gridRowLimit
                },
                search: false,
                resizable: true,
                sort: true,
                data: dataSet,
                className: {
                    table: 'cleanComponent inAnimation',
                    tr: 'cleanComponent',
                    thead: 'cleanComponent',
                    tbody: 'cleanComponent',
                    th: 'cleanComponent thgrid',
                    td: 'cleanComponent tdgrid'
                },
                language: {
                    'search': {
                        'placeholder': '🔍 Procurar...'
                    },
                    'pagination': {
                        'previous': 'Anterior',
                        'next': 'Próximo',
                        'showing': 'Exibindo',
                        'results': () => 'Registros',
                        'to': 'até',
                        'of': 'de'
                    }
                }
            })
            )
        }
    }

    // const addGridID = async () => {
    //     let t = document.getElementsByClassName("gridjs-table")[0]
    //     if (t) {
    //         t.id = 'table_' + id
    //     }
    // }

    const asyncRenderGrid = async () => {
        try {
            grid.render(document.getElementById(id))
        } catch (e) { }
    }

    useEffect(() => {
        if (grid != null) {
            const callRenderAndSetID = async () => {
                if (isFirsRenderGrid) {//Renderiza e add o ID só 1 vez
                    await asyncRenderGrid()
                    // await addGridID()
                    setIsFirsRenderGrid(false)
                }
            }
            callRenderAndSetID()
        }
    }, [grid])

    useEffect(() => {
        const asyncGridConfig = async () => {
            if (isShowGrid) {
                await gridConf()
                // let t = document.getElementsByClassName("gridjs-table")[0]
                // t.id = 'table_' + id
            }
        }
        asyncGridConfig()
    }, [isShowGrid])

    // const filterColumns = (key) => {
    //     let filterList = []
    //     for (let index = 0; index < data.length; index++) {
    //         const element = data[index];
    //         setIsShowGrid(false)
    //         if (element[key].includes(document.getElementById('filterButtonGridID_' + key).value)) {
    //             filterList.push(element)
    //         }

    //         if (index + 1 == data.length) {
    //             setDataSet(filterList)
    //             // setIsShowGrid(true)
    //         }
    //     }
    // }

    const filterColumns = (key) => {
        let filterList = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            setIsShowGrid(false)

            //Percorre as propriedades do objeto
            let currentIndexProp = 0 //Quando for igual ao parametro key é a chave que queremos filtrar
            for (let propName in element) {
                if (element.hasOwnProperty(propName)) {
                    if (currentIndexProp == key) {
                        if ((element[propName].toLowerCase()).includes((document.getElementById('filterButtonGridID_' + key).value).toLowerCase())) {
                            filterList.push(element)
                        }
                        currentIndexProp = 0 //Reseta para olha do zero index zero no próximo objeto
                        break //Já achou a coluna para filtrar nesse objeto, então passa para o próximo
                    } else {
                        currentIndexProp++
                    }
                }
            }

            if (index + 1 == data.length) {
                setDataSet(filterList)
                // setIsShowGrid(true)
            }
        }
    }

    //Quando alterar o limite por página na grid... renderiza de novo a grid
    useEffect(() => {
        const asynGridUpdate = async () => {
            loadingFunction(true)
            // await gridUpdate()
            // await addGridID()

            // await gridConf()
            // await asyncRenderGrid()
            await gridUpdate()
            // await addGridID()

            // await asyncRenderGrid()
            // await addGridID()

            // if (gridRowLimit.download) {
            //     console.log("gridRowLimit.download...gridRowLimit.download")
            //     console.log(gridRowLimit.download)
            //     setTimeout(() => {
            //         document.getElementById('btnDownloadGridID').click()
            //         setGridRowLimit({ limit: 5, download: false })
            //         loadingFunction(false)
            //     }, 300)
            // } else {
            //     loadingFunction(false)
            // }
        }
        asynGridUpdate()
    }, [gridRowLimit])

    const gridUpdate = async () => {
        if (grid != null) {
            try {
                grid.updateConfig({
                    columns: columns,
                    pagination: {
                        limit: gridRowLimit.limit
                    },
                    search: false,
                    resizable: true,
                    sort: true,
                    data: dataSet,
                    className: {
                        table: 'cleanComponent inAnimation',
                        tr: 'cleanComponent',
                        thead: 'cleanComponent',
                        tbody: 'cleanComponent',
                        th: 'cleanComponent thgrid',
                        td: 'cleanComponent tdgrid'
                    },
                    language: {
                        'search': {
                            'placeholder': '🔍 Procurar...'
                        },
                        'pagination': {
                            'previous': 'Anterior',
                            'next': 'Próximo',
                            'showing': 'Exibindo',
                            'results': () => 'Registros',
                            'to': 'até',
                            'of': 'de'
                        }
                    }
                }).forceRender()
            } catch (e) {
                loadingFunction(false)
            }
        } else {
            loadingFunction(false)
        }
    }

    const downloadData = async () => {
        // loadingFunction(true)
        // setGridRowLimit({ limit: dataSet.length, download: true })
        // setTimeout(() => {
        //     loadingFunction(false)
        // }, 3000)
        loadingFunction(true)
        setIsCreateTableForDownload(true) //habilita a criação da tabela para download

        try {
            if (document.getElementById('table_' + id)) {//Se a tabela já foi criada baixa os dados
                document.getElementById('btnDownloadGridID').click()
                loadingFunction(false)
            } else {
                setTimeout(() => {//se a tabela ainda não foi criada... esper aum pouquinho e tenta de novo
                    downloadData()
                }, 300)
            }
        } catch (error) {
            setTimeout(() => {
                downloadData()
            }, 300)
        }
    }

    return (
        <div className="row minUse100">
            <div className="col-12 secondColor smallShadow imgCardBox use100 radius12">
                <div className="row">
                    <div className="col-12 padding30">
                        <span className="title">{title}</span>
                        <p className="subTitle">{subTitle}</p>
                    </div>
                    <div className="col-12 toRight">
                        <ReactagleButton text="Download" func={() => { downloadData() }} />
                        <div className="marginRight7"></div>
                        <ReactHTMLTableToExcel
                            id="btnDownloadGridID"
                            className="invisible btn mainGradBK whiteFont padding20Sidex marginRight7 delay"
                            table={`table_${id}`}
                            filename="chamados"
                            sheet="Chamados"
                            buttonText="Download"
                        />
                        <ReactagleButton text="Filtros" func={() => { setIsShowFilters(!isShowFilters) }} />
                    </div>
                    {/* {
                        isShowFilters &&
                        <div className="col-12 padding30">
                            <div className="row">
                                {
                                    columns.map((item, k) => {
                                        return <div key={k} className="col-12 col-sm-4 col-md-3">
                                            <OutlineButton id={`filterButtonGridID_${k}`} label={item} placeholder={item + '...'} onChangeFunc={(e) => { filterColumns(k) }} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    } */}
                    {
                        isShowFilters &&
                        <div className="col-12 padding30">
                            <div className="row">
                                {
                                    // columns.map((item, k) => {
                                    //     return <div key={k} className="col-12 col-sm-4 col-md-3">
                                    //         <OutlineButton id={`filterButtonGridID_${k}`} label={item} placeholder={item + '...'} onChangeFunc={(e) => { filterColumns(k) }} />
                                    //     </div>
                                    // })

                                    Object.keys(columns).map(function (propNumber, keyPropName) {
                                        if (columns.hasOwnProperty(propNumber)) {
                                            return (
                                                //Não vamos exibir se for coluna id ou ação
                                                columns[propNumber].name === "id" ?
                                                    <div></div>
                                                    :
                                                    columns[propNumber].name === "Ação" ? <div></div>
                                                        :
                                                        columns[propNumber].hidden ? <div></div>
                                                            :
                                                            <div key={keyPropName} className="col-12 col-sm-4 col-md-3">
                                                                <OutlineField id={`filterButtonGridID_${keyPropName}`} label={`${columns[propNumber].name}`} placeholder={columns[propNumber].name + '...'} onChangeFunc={(e) => { filterColumns(propNumber) }} />
                                                            </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    }
                    <div className="col-12">
                        {
                            isShowGrid &&
                            <div id={id}></div>
                        }
                    </div>
                    {
                        isCreateTableForDownload &&
                        <div className="invisible col-12 padding30">
                            <div className="row">
                                <table id={`table_${id}`}>
                                    <thead>
                                        <tr>
                                            {
                                                columns.map((item, k) => {
                                                    return (
                                                        item.name === "id" ?
                                                            <></>
                                                            :
                                                            item.name === "Ação" ?
                                                                <></>
                                                                :
                                                                <th key={k}>{item.name}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataSet.map((item, k) => {
                                                return <tr key={k}>
                                                    {
                                                        Object.keys(item).map(function (propName, keyPropName) {
                                                            return (
                                                                propName === "id" ?
                                                                    <></>
                                                                    :
                                                                    <td key={keyPropName} >
                                                                        {item[propName]}
                                                                    </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default TableGrid