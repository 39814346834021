import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import isAuthenticated from './config/service/auth'
import isAdmAuthenticated from './config/service/authAdm'
import HelpDesk from './config/pages/helpDesk'
import Intro from './config/pages/intro'
import Login from './config/pages/login'
import Tasks from './config/pages/tasks'
// import Home from './config/pages/home'
import Users from './config/pages/users'
import Material from './config/pages/material'
import MaterialGroup from './config/pages/materialGroup'
import MaterialSubGroup from './config/pages/materialSubGroup'
import Task from './config/pages/task'
import Problem from './config/pages/problem'
import Citys from './config/pages/citys'
import PDFPage from './config/pages/pdf'
import PDFReporter from './config/pages/pdfReport'
import PDFGenerator from './config/pages/pdfGenerator'
import PDFGeneratorTest from './config/pages/pdfGeneratorTest'
import MaterialsGroups from './config/pages/materialsGroups'

const AssignRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => isAuthenticated() ? (
                <Component {...props} />
            )
                :
                (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
)

const AssignAdmRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => isAdmAuthenticated() ? (
                <Component {...props} />
            )
                :
                (
                    <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
                )
        }
    />
)

const Routes = () => (
    <BrowserRouter>
        <Switch>
            {/* <AssignRoute exact path="/home" component={Home} /> */}
            <AssignRoute exact path="/chamados" component={Tasks} />
            <AssignAdmRoute exact path="/usuarios" component={Users} />
            <AssignAdmRoute exact path="/problema" component={Problem} />
            <AssignAdmRoute exact path="/cidades" component={Citys} />
            <AssignRoute exact path="/chamado" component={Task} />
            <AssignRoute exact path="/material" component={Material} />
            <AssignRoute exact path="/materialGroup" component={MaterialGroup} />
            <AssignRoute exact path="/materialSubGroup" component={MaterialSubGroup} />
            {/* <AssignRoute exact path="/pdfrelatorio" component={PDFReporter} /> */}
            <AssignRoute exact path="/pdfrelatorio" component={PDFGenerator} />
            <AssignRoute exact path="/materialgrupos" component={MaterialsGroups} />
            <Route exact path="/atendimento" component={HelpDesk} />
            {/* <Route exact path="/atendimentospdf" component={PDFGenerator} /> */}
            <Route exact path="/atendimentospdf" component={PDFGeneratorTest} />
            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/pdf" component={PDFPage} /> */}
            <Route component={Intro} />
        </Switch>
    </BrowserRouter>
)

export default Routes