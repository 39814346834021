import React, { Component } from 'react'
import './styles.css'

export default class OverMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lst: props.lst
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.show ?
                        (
                            <div className={"overMessageBox animate__animated animate__fadeInDown center boxShadow text-center whiteFont " + (this.props.type == "success" ? "successBk" : this.props.type == "warning" ? "warningBk" : "errorBk")}>
                                <h6>{this.props.message}</h6>
                            </div>
                            // <div className={"overMessageBox whiteFont "+(this.props.type == "success" ? "successBk":"errorBk")}>
                            //     <div className="row">
                            //         {
                            //             this.props.title ? (
                            //                 <div>
                            //                     <div className="col-12">
                            //                         <h3>{this.props.title}</h3>
                            //                     </div>
                            //                     <div className="line"></div>
                            //                 </div>
                            //             )
                            //                 :
                            //                 null
                            //         }
                            //     </div>
                            //     <div className="row text-center">
                            //         <div className="col-12">
                            //             <h6>{this.props.message}</h6>
                            //         </div>
                            //     </div>
                            // </div>
                        )
                        :
                        null
                }
            </div>
        )
    }

}