import React, { useState, useEffect } from 'react'

//css
// import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'
import TableGrid from '../../components/tableGrid'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'

//GridJS
import { h } from "gridjs"

const Citys = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isShowTable, setIsShowTable] = useState(true)
    const [citys, setCitys] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedCity, setSelectedCity] = useState()

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findCitys = async () => {
        const response = await rest.get('/city/v1/findAllView')
        setCitysData(response.data)
        console.log("findCitys...")
        console.log(response.data)
    }

    const registerCity = async () => {
        let city = document.getElementById("edtCity").value
        let uf = document.getElementById("edtUf").value
        let sla = document.getElementById("edtSLA").value

        //CLA de no minimo 1h
        if (sla == '' || sla == null || parseInt(sla) < 1) {
            setOverMessage({
                show: true,
                message: 'Favor informar o SLA',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else if (city == '' || uf == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar cidade e UF',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/city/v1/save', {
                    name: city,
                    uf: uf,
                    sla: parseInt(sla)
                })

                document.getElementById("edtCity").value = ''
                document.getElementById("edtUf").value = ''
                document.getElementById("edtSLA").value = ''
                await findCitys()
                setIsLoading(false)
                setOverMessage({
                    show: true,
                    message: 'Cidade cadastrada com sucesso',
                    type: 'success'
                })
                setIsLoading(false)
                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar a cidade',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerCity()
    }

    // useEffect(() => {
    //     translateSearch()
    //     findCitys()
    // }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }


    const updateCity = async () => {
        let city = document.getElementById("edtCityUpdate").value
        let uf = document.getElementById("edtUfUpdate").value
        let sla = document.getElementById("edtSLAUpdate").value

        //CLA de no minimo 1h
        if (sla == '' || sla == null || parseInt(sla) < 1) {
            setOverMessage({
                show: true,
                message: 'Favor informar o SLA',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else if (city == '' || uf == '') {
            setOverMessage({
                show: true,
                message: 'Favor informar cidade e UF',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedCity.id > 0) {
                    const response = await rest.post('/city/v1/update', {
                        id: selectedCity.id,
                        name: city,
                        uf: uf,
                        sla: parseInt(sla)
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findCitys()
                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'Cidade alterada com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar uma cidade',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar a cidade',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setCitys(newData)
    }

    /* GridJS */
    const [citysData, setCitysData] = useState([])
    const [citysColumns, setCitysColumns] = useState([
        {
            id: 'id',
            name: 'id',
            hidden: true
        },
        {
            id: 'cod',
            name: 'Código'
        },
        {
            id: 'city',
            name: 'Cidade'
        },
        {
            id: 'uf',
            name: 'UF'
        },
        {
            id: 'sla',
            name: 'SLA'
        },
        {
            name: 'Ação',
            formatter: (cell, row) => {
                return h('button', {
                    className: 'btn mainGradBK whiteFont',
                    onClick: () => changeSelectedCity(row.cells[0].data, row.cells[2].data, row.cells[3].data, row.cells[4].data)
                }, 'Editar');
            }
        }
    ])

    useEffect(() => {
        // translateSearch()
        setSelectedCity(null)
    }, [citysData])

    useEffect(() => {
        setIsShowTable(false)
    }, [citysData])

    const selectedHelpDesk = (helpDeskID) => {
        console.log(helpDeskID)
    }

    const changeSelectedCity = (id, city, uf, sla) => {
        setSelectedCity({
            id: id,
            name: city,
            uf: uf,
            sla: sla
        })
    }
    useEffect(() => {
        if (selectedCity) {
            if (selectedCity.id > 0) {
                document.getElementById('btnUpdateCityModal').click()
            }
        }
    }, [selectedCity])

    const showMessage = (message, type) => {
        setOverMessage({
            show: true,
            message: message,
            type: type
        })
        setTimeout(() => {
            setOverMessage({
                show: false,
                message: '',
                type: ''
            })
        }, 4000)
    }

    useEffect(() => {
        const asyncLoadStart = async () => {
            try {
                await findCitys()
                setIsLoading(false)
            } catch (e) {
                showMessage('Falha ao consultar a lista de cidades', 'error')
                setIsLoading(false)
            }
        }
        asyncLoadStart()
    }, [])

    useEffect(() => {
        if (!isShowTable) {
            setIsShowTable(true)
        }
    }, [isShowTable])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <div className='row'>
                            <div className="col-12">
                                <span className="title">Cadastro</span>
                                <p className="subTitle">Cadastrar Cidade</p>
                                <div className='mainDegLine'></div>
                            </div>
                        </div>
                        <div className="row top20">
                            <div className="col-12 col-md-3 top10">
                                <FieldText id="edtCity" name="edtCity" placeholder="Cidade" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <FieldText id="edtUf" name="edtUf" placeholder="UF" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <FieldText id="edtSLA" name="edtSLA" type='number' placeholder="SLA em horas" />
                            </div>
                            <div className="col-12 col-sm-2 top10">
                                <div onClick={e => registerCity()}>
                                    <ButtonRectangle label="Cadastrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row top10 padding15">
                    <div className="col-12">
                        {
                            isShowTable &&
                            <TableGrid id="cityGridId" title="Relatório" subTitle="Geranciar Cidades" columns={citysColumns} data={citysData} loadingFunction={setIsLoading} />
                        }
                    </div>


                    <button data-toggle="modal" id='btnUpdateCityModal' data-target='#updateCityModal' className='invisible btn mainGradBK whiteFont padding20Side delay'></button>
                    <div class="modal fade" id='updateCityModal' tabindex="-1" role="dialog" aria-labelledby="updateCityModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar Cidade</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedCity &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> */}
                                                    <div className="col-12 top10">
                                                        <FieldText id="edtCityUpdate" name="edtCityUpdate" placeholder="Cidade" value={selectedCity ? selectedCity.name : ''} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> */}
                                                    <div className="col-12 top10">
                                                        <FieldText id="edtUfUpdate" name="edtUfUpdate" placeholder="UF" value={selectedCity ? selectedCity.uf : ''} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 top10">
                                                        <FieldText id="edtSLAUpdate" name="edtSLAUpdate" type='number' placeholder="SLA em horas" value={selectedCity ? selectedCity.sla : ''} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 top10 toRight">
                                                        <div onClick={e => updateCity()}>
                                                            <ButtonRectangle label="Salvar" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className="row top40">
                    <div className="col-12">
                        {
                            citys && <CSVLink
                                filename='arquivo.csv'
                                className="btn mainGradBK whiteFont padding20Side delay"
                                data={citys}>
                                Download
                            </CSVLink>
                        }
                    </div>
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <table className="table table-hover">
                            <thead>
                                {
                                    citys &&
                                    <TableFilter
                                        rows={citys}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="name">
                                            Cidade
                                        </th>
                                        <th filterkey="uf">
                                            UF
                                        </th>
                                        <th filterkey="cla">
                                            SLA
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    citys &&
                                    citys.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.uf}</td>
                                            <td>{item.sla}h</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateCityModal' className='btn mainGradBK whiteFont padding20Side delay' onClick={(e) => changeSelectedCity(item)}>Editar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal fade" id='updateCityModal' tabindex="-1" role="dialog" aria-labelledby="updateCityModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar Cidade</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedCity &&
                                        <div className="row">
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> * /}
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtCityUpdate" name="edtCityUpdate" placeholder="Cidade" value={selectedCity ? selectedCity.name : ''} />
                                            </div>
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> * /}
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtUfUpdate" name="edtUfUpdate" placeholder="UF" value={selectedCity ? selectedCity.uf : ''} />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtSLAUpdate" name="edtSLAUpdate" type='number' placeholder="SLA em horas" value={selectedCity ? selectedCity.sla : ''} />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <div onClick={e => updateCity()}>
                                                    <ButtonRectangle label="Salvar" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> * /}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div >
    )
}

export default Citys