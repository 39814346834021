import React, { useState, useEffect } from 'react'

//css
// import './styles.css'

//Service
import rest from '../../service/restAuth'

//Components
import MenuBar from '../../components/menuBar'
import Footer from '../../components/footer'
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import IconCard from '../../components/iconCard'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import OverMessage from '../../components/overMessage'

//PrimeReact
import { Chart } from 'primereact/chart';

//Other
import { CSVLink, CSVDownload } from 'react-csv'
import TableFilter from 'react-table-filter'

import TableGrid from '../../components/tableGrid'

//GridJS
import { h } from "gridjs"

const MaterialSubGroup = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isShowTable, setIsShowTable] = useState(true)
    const [materialSubGroups, setMaterialSubGroups] = useState()
    const [materialGroups, setMaterialGroups] = useState()
    const [filterData, setFilterData] = useState()
    const [selectedSubGroup, setselectedSubGroup] = useState()

    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const findAllMaterialGroups = async () => {
        const response = await rest.get('/material/v1/findAllMaterialGroups')
        setMaterialGroups(response.data)
    }

    const findAllMaterialSubGroups = async () => {
        const response = await rest.get('/material/v1/findAllMaterialSubGroupsView')
        setMaterialSubGroupsData(response.data)
    }

    const registerMaterialSubGroup = async () => {
        let name = document.getElementById("edtMaterialSubGroupName").value
        let groupID = document.getElementById('edtGroup').value

        if (name == '' || groupID == '0') {
            setOverMessage({
                show: true,
                message: 'Favor informar o nome do subgrupo e o grupo',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                const response = await rest.post('/material/v1/saveSubGroup', {
                    name: name,
                    materialGroup: {
                        id: parseInt(groupID)
                    }
                })

                document.getElementById("edtMaterialSubGroupName").value = ''
                document.getElementById("edtGroup").value = '0'
                await findAllMaterialSubGroups()
                setIsLoading(false)
                setOverMessage({
                    show: true,
                    message: 'SubGrupo cadastrado com sucesso',
                    type: 'success'
                })
                setIsLoading(false)
                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar o subgrupo',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            registerMaterialSubGroup()
    }

    useEffect(() => {
        translateSearch()
        findAllMaterialSubGroups()
        findAllMaterialGroups()
    }, [])

    const translateSearch = () => {
        let eSrarchs = document.getElementsByClassName("search-input");
        let i;
        for (i = 0; i < eSrarchs.length; i++) {
            eSrarchs[i].placeholder = "Procurar...";
        }
    }


    const updateMaterialSubGroup = async () => {
        let name = document.getElementById("edtMaterialSubGroupNameUpdate").value
        let groupID = document.getElementById('edtGroupUpdate').value
        console.log(selectedSubGroup)
        console.log(name)
        if (name == '' || groupID == '0') {
            setOverMessage({
                show: true,
                message: 'Favor informar o grupo e subgrupo',
                type: 'warning'
            })
            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 4000)
        } else {
            try {
                setIsLoading(true)
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })

                if (selectedSubGroup.id > 0) {
                    const response = await rest.post('/material/v1/updateSubGroup', {
                        id: selectedSubGroup.id,
                        name: name,
                        materialGroup: {
                            id: parseInt(groupID)
                        }
                    })

                    console.log('updated...')
                    console.log(response)
                    console.log({
                        id: selectedSubGroup.id,
                        name: name,
                        materialGroup: {
                            id: parseInt(groupID)
                        }
                    })

                    // document.getElementById("edtCityUpdate").value = ''
                    // document.getElementById("edtUfUpdate").value = ''
                    await findAllMaterialSubGroups()
                    document.getElementById('btnCloseModalId').click()
                    setOverMessage({
                        show: true,
                        message: 'SubGrupo alterado com sucesso',
                        type: 'success'
                    })
                    setIsLoading(false)
                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                } else {
                    setOverMessage({
                        show: false,
                        message: 'Favor selecionar um subgrupo',
                        type: 'warning'
                    })
                }
                setIsLoading(false)

            } catch (err) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar o subgrupo',
                    type: 'error'
                })
                setIsLoading(false)
            }
        }
    }

    const changeselectedSubGroup = (item) => {
        setselectedSubGroup(item)
    }

    useEffect(() => {
        translateSearch()
        setselectedSubGroup(null)
    }, [materialSubGroups])

    const filterUpdated = (newData, filterConfiguration) => {
        // setFilterData(newData)
        setMaterialSubGroups(newData)
    }

    /* GridJS */

    const [materialSubGroupsData, setMaterialSubGroupsData] = useState([])
    const [materialSubGroupsColumns, setMaterialSubGroupsColumns] = useState([
        {
            id: 'id',
            name: 'id',
            hidden: true
        },
        {
            id: 'cod',
            name: 'Código'
        },
        {
            id: 'name',
            name: 'Nome'
        },
        {
            id: 'groupID',
            name: 'CodGrupo',
            hidden: true
        },
        {
            id: 'group',
            name: 'Grupo'
        },
        {
            id: 'city',
            name: 'Cidade'
        },
        {
            name: 'Ação',
            formatter: (cell, row) => {
                return h('button', {
                    className: 'btn mainGradBK whiteFont',
                    onClick: () => selectedMaterialGroup(row.cells[0].data, row.cells[2].data, row.cells[3].data)
                }, 'Editar')
            }
        },
        // {
        //     name: 'Ação',
        //     formatter: (cell, row) => {
        //         return h('button', {
        //             className: 'btn mainGradBK whiteFont',
        //             onClick: () => selectedHelpDesk(row.cells[0].data)
        //         }, 'PDF');
        //     }
        // },
    ])

    useEffect(() => {
        setIsShowTable(false)
        setselectedSubGroup(null)
    }, [materialSubGroupsData])

    const selectedMaterialGroup = (materialSubGroupID, materialSubGroupNane, materialGroupID) => {
        setselectedSubGroup({
            id: materialSubGroupID,
            name: materialSubGroupNane,
            materialGroup: {
                id: materialGroupID
            }
        })
    }

    useEffect(() => {
        if (selectedSubGroup) {
            if (selectedSubGroup.id > 0) {
                document.getElementById('btnUpdateMaterialSubGroupSubModal').click()
            }
        }
    }, [selectedSubGroup])

    const showMessage = (message, type) => {
        setOverMessage({
            show: true,
            message: message,
            type: type
        })
        setTimeout(() => {
            setOverMessage({
                show: false,
                message: '',
                type: ''
            })
        }, 4000)
    }

    useEffect(() => {
        const asyncLoadStart = async () => {
            await findAllMaterialSubGroups()
            setIsLoading(false)
        }
        asyncLoadStart()
    }, [])

    useEffect(() => {
        if (!isShowTable) {
            setIsShowTable(true)
            setIsLoading(false)
        }
    }, [isShowTable])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div id="top">
                <MenuBar />
            </div>
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full padding100Top container">
                <div className="row">
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30
">
                        <div className="row">
                            <div className="col-12">
                                <span className="title">Cadastro</span>
                                <p className="subTitle">Cadastrar SubGrupo</p>
                                <div className='mainDegLine'></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3 top30">
                                <FieldText id="edtMaterialSubGroupName" name="edtMaterialSubGroupName" placeholder="SubGrupo" />
                            </div>
                            <div className="col-12 col-md-3 top10">
                                <div className="boxSelect">
                                    {
                                        <div id='boxSelectGroupId' className='borderGray'>
                                            <label for='' id='' className="labelSelect">Grupo</label><br />
                                            <select name="edtGroup" id="edtGroup" className="selectOptionsBox borderNone">
                                                <option value='0' className="selectOption blackFont" selected>Selecionar Grupo</option>
                                                {
                                                    materialGroups &&
                                                    materialGroups.map((item, k) => {
                                                        return (
                                                            <option key={k} value={item.id} className="selectOption blackFont">{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-sm-2 top30">
                                <div onClick={e => registerMaterialSubGroup()}>
                                    <ButtonRectangle label="Cadastrar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top10 padding15">
                    <div className="col-12">
                        {
                            isShowTable &&
                            <TableGrid id="materialSubGroupGridId" title="Relatório" subTitle="Geranciar SubGrupos" columns={materialSubGroupsColumns} data={materialSubGroupsData} loadingFunction={setIsLoading} />
                        }
                    </div>
                </div>

                <button data-toggle="modal" id='btnUpdateMaterialSubGroupSubModal' data-target='#updateMaterialSubGroupSubModal' className='invisible btn mainGradBK whiteFont padding10 padding20Side delay'></button>
                <div class="modal fade" id='updateMaterialSubGroupSubModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialSubGroupSubModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalLongTitle">Editar SubGrupo</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {
                                    selectedSubGroup &&
                                    <div className="row">
                                        {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> */}
                                        <div className="col-12 padding30">
                                            <div className="row">
                                                <div className="col-12 top10">
                                                    <FieldText id="edtMaterialSubGroupNameUpdate" name="edtMaterialSubGroupNameUpdate" placeholder="SubGrupo" label="SubGrupo" value={selectedSubGroup ? selectedSubGroup.name : ''} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 top10">
                                                    <div className="boxSelect">
                                                        {
                                                            <div id='boxSelectGroupId' className='borderGray'>
                                                                <label for='' id='' className="labelSelect">Grupo</label><br />
                                                                <select name="edtGroupUpdate" id="edtGroupUpdate" className="selectOptionsBox borderNone">
                                                                    <option value='0' className="selectOption blackFont" selected>Selecionar Grupo</option>
                                                                    {
                                                                        materialGroups &&
                                                                        materialGroups.map((item, k) => {
                                                                            return (
                                                                                item.id == selectedSubGroup.materialGroup.id ?
                                                                                    <option key={k} value={item.id} className="selectOption blackFont" selected>{item.name}</option>
                                                                                    :
                                                                                    <option key={k} value={item.id} className="selectOption blackFont">{item.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 top10 toRight">
                                                    <div onClick={e => updateMaterialSubGroup()}>
                                                        <ButtonRectangle label="Salvar" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="modal-footer">
                                <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="row top40">
                    <div className="col-12">
                        {
                            materialSubGroups && <CSVLink
                                filename='arquivo.csv'
                                className="btn mainGradBK whiteFont padding10 padding20Side delay"
                                data={materialSubGroups}>
                                Download
                            </CSVLink>
                        }
                    </div>
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <table className="table table-hover">
                            <thead>
                                {
                                    materialSubGroups &&
                                    <TableFilter
                                        rows={materialSubGroups}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="id" filterkey="id" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Código
                                        </th>
                                        <th filterkey="name">
                                            Nome
                                        </th>
                                        <th filterkey="name">
                                            Grupo
                                        </th>
                                        <th></th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    materialSubGroups &&
                                    materialSubGroups.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.materialGroup.name}</td>
                                            <td>
                                                <button data-toggle="modal" data-target='#updateMaterialSubGroupSubModal' className='btn mainGradBK whiteFont padding10 padding20Side delay' onClick={(e) => changeselectedSubGroup(item)}>Editar</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="modal fade" id='updateMaterialSubGroupSubModal' tabindex="-1" role="dialog" aria-labelledby="updateMaterialSubGroupSubModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modalLongTitle">Editar SubGrupo</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {
                                        selectedSubGroup &&
                                        <div className="row">
                                            {/* <div className="col-12 col-md-3 top10" onKeyDown={e => pressEnter(e)}> * /}
                                            <div className="col-12 col-md-8 top10">
                                                <FieldText id="edtMaterialSubGroupNameUpdate" name="edtMaterialSubGroupNameUpdate" placeholder="SubGrupo" value={selectedSubGroup ? selectedSubGroup.name : ''} />
                                            </div>
                                            <div className="col-12 col-md-8 top10">
                                                <div className="boxSelect">
                                                    {
                                                        <div id='boxSelectGroupId' className='borderGray'>
                                                            <label for='' id='' className="labelSelect">Grupo</label><br />
                                                            <select name="edtGroupUpdate" id="edtGroupUpdate" className="selectOptionsBox borderNone">
                                                                <option value='0' className="selectOption blackFont" selected>Selecionar Grupo</option>
                                                                {
                                                                    materialGroups &&
                                                                    materialGroups.map((item, k) => {
                                                                        return (
                                                                            item.id == selectedSubGroup.materialGroup.id ?
                                                                                <option key={k} value={item.id} className="selectOption blackFont" selected>{item.name}</option>
                                                                                :
                                                                                <option key={k} value={item.id} className="selectOption blackFont">{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 top10">
                                                <div onClick={e => updateMaterialSubGroup()}>
                                                    <ButtonRectangle label="Salvar" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button id='btnCloseModalId' type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                    {/* <button type="button" class="btn btn-primary">Save changes</button> * /}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div >
    )
}

export default MaterialSubGroup