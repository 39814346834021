import React, { useEffect, useState } from 'react'
import Loading from '../../components/loadingBar'
import rest from '../../service/restAuth'
import Footer from '../../components/footer'
import { CSVLink, CSVDownload } from 'react-csv'
import LoadingBar from '../../components/loadingBar'
import MenuBar from '../../components/menuBar'
import TableFilter from 'react-table-filter'
import { Link } from 'react-router-dom'
import task from '../task'
import FieldText from '../../components/fieldText'
import ButtonRectangle from '../../components/buttonRectangle'
import Inputmask from 'inputmask'
import OverMessage from '../../components/overMessage'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import TableGrid from '../../components/tableGrid'
import { useHistory } from "react-router"

//GridJS
import { h } from "gridjs"

const HelpDesks = () => {
    let history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [tasks, setTasks] = useState()
    const [csvData, setCsvData] = useState([])
    const [periodOne, setPeriodOne] = useState('')
    const [periodTwo, setPeriodTwo] = useState('')
    const [isShowTable, setIsShowTable] = useState(true)
    const [isChangeBySQL, setIsChangeBySQL] = useState(false)
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const [isInvisibleForColumns, setIsInvisibleForColumns] = useState(false)

    const findTasks = async () => {
        console.log('const findTasks = async () => {...')
        try {
            setIsLoading(true)
            const response = await rest.get('/helpdesk/v1/findAllView')
            // setTasks(response.data)
            setHelpDeskData(response.data)
            console.log('response.data.../helpdesk/v1/findAllView')
            console.log(response.data)
            setIsLoading(false)
            // setIsChangeBySQL(true)
        } catch (e) {
            setIsLoading(false)
        }
    }

    // const filterByDate = async () => {
    //     try {
    //         let validPeriodOne = document.getElementById('edtPeriodOne').value
    //         let validPeriodTwo = document.getElementById('edtPeriodTwo').value
    //         if (validPeriodOne == '' && validPeriodTwo == '') {// sem nenhum filtro, traz a base toda
    //             findTasks()
    //         } else if (validPeriodOne == '' || validPeriodTwo == '') {// não filtra pq precisa informar as duas datas(de/até)
    //             setOverMessage({
    //                 show: true,
    //                 message: 'Favor informar as duas datas (de/até)',
    //                 type: 'error'
    //             })

    //             setTimeout(() => {
    //                 setOverMessage({
    //                     show: false,
    //                     message: '',
    //                     type: ''
    //                 })
    //             }, 3000)
    //         } else {//filtra por período
    //             setIsLoading(true)
    //             const response = await rest.get(`/helpdesk/v1/findAllByPeriod/${validPeriodOne}-${validPeriodTwo}`)
    //             setTasks(response.data ? response.data : [])
    //             setIsLoading(false)
    //             setIsChangeBySQL(true)
    //         }
    //     } catch (e) {
    //         setIsLoading(false)
    //     }
    // }

    const filterHelpDesks = async () => {
        try {
            let validPeriodOne = document.getElementById('edtPeriodOne').value
            let validPeriodTwo = document.getElementById('edtPeriodTwo').value
            let validProtocol = document.getElementById('edtProtocol').value
            let validNeighborhood = document.getElementById('edtNeighborhood').value
            let validStreet = document.getElementById('edtStreet').value
            let validUsername = document.getElementById('edtUsername').value

            if (validPeriodOne == '' && validPeriodTwo != '') {//se informar só a segunda data... pede para informar as duas
                setOverMessage({
                    show: true,
                    message: 'Favor informar as duas datas (de/até)',
                    type: 'error'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } else {
                setIsLoading(true)
                const response = await rest.post(`/helpdesk/v1/findbyfilterView`, {
                    FinishPeriodDateOne: validPeriodOne,
                    FinishPeriodDateTwo: validPeriodTwo,
                    CityID: '',
                    Protocol: validProtocol,
                    Neighborhood: validNeighborhood,
                    Street: validStreet,
                    Username: validUsername,
                    IsLoadData: 'true'
                })
                // setTasks(response.data ? response.data : [])
                setHelpDeskData(response.data)
                setIsLoading(false)
                // setIsChangeBySQL(true)
                console.log("ok...")
            }
        } catch (e) {
            setIsLoading(false)
        }
    }

    window.onscroll = function (oEvent) {
        checkHeadTable()
    }

    const checkHeadTable = () => {
        if (window.pageYOffset >= 100) {
            // document.getElementById('headLineTableId').classList.add('topFixed')
        } else {
            // document.getElementById('headLineTableId').classList.remove('topFixed')
        }
    }

    const filterUpdated = (newData, filterConfiguration) => {
        setTasks(newData)
    }

    const downloadPDFsFiles = async () => {
        tasks.forEach(t => {
            // var link = document.createElement("a");
            // link.download = t.protocol;
            // link.href = 'http://phpserver.protocolosjjeletrica.com.br/pdffiles/' + t.protocol + '.pdf';
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            // delete link;

            fetch('http://phpserver.protocolosjjeletrica.com.br/pdffiles/' + t.protocol + '.pdf', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `FileName.pdf`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                });

        })
    }

    // const donwloadXLS = async () => {
    //     setIsLoading(true)
    //     /*
    //         seta a isInvisibleForColumns assim oculta as colunas que queremos
    //     */
    //     console.log('donwloadXLS...donwloadXLS...')
    //     setIsInvisibleForColumns(true)
    //     /*
    //        Se isInvisibleForColumns for true, em algum momentos ocultamos as colunas que queremos
    //        deixar de fora do XLS, assim clicamos para baixar o XLS e depois voltamos para false,
    //        fazendo com que as colunas aparecam novamente
    //    */
    //     setTimeout(() => {
    //         setIsLoading(false)
    //         document.getElementById('test-table-xls-button').click()
    //         setIsInvisibleForColumns(false)
    //     }, 500)
    // }

    // useEffect(() => {
    //     if (isInvisibleForColumns) {
    //         setIsInvisibleForColumns(false)
    //     }
    // }, [isInvisibleForColumns])

    useEffect(() => {
        let fieldPeriodOne = document.getElementById("edtPeriodOne") ? document.getElementById("edtPeriodOne") : null
        let fieldPeriodTwo = document.getElementById("edtPeriodTwo") ? document.getElementById("edtPeriodTwo") : null

        var imDate = new Inputmask("99/99/9999")
        if (fieldPeriodOne != null) imDate.mask(fieldPeriodOne)
        if (fieldPeriodTwo != null) imDate.mask(fieldPeriodTwo)
        findTasks()
    }, [])

    // useEffect(() => {
    //     if (isChangeBySQL) {
    //         setIsLoading(true)
    //         setIsShowTable(false)
    //         setTimeout(() => {
    //             setIsLoading(false)
    //             setIsShowTable(true)
    //             setIsChangeBySQL(false)
    //         }, 300)
    //     }
    // }, [isChangeBySQL])

    // useEffect(() => {
    //     setIsLoading(false)
    // }, [])

    /* GridJS */

    const [helpDeskData, setHelpDeskData] = useState([])
    const [helpDeskColumns, setHelpDeskColumns] = useState([
        {
            id: 'id',
            name: 'id',
            hidden: true
        },
        {
            id: 'protocol',
            name: 'Protocolo'
        },
        {
            id: 'status',
            name: 'Status'
        },
        {
            id: 'city',
            name: 'Cidade'
        },
        {
            id: 'neighborhood',
            name: 'Bairro'
        },
        {
            id: 'street',
            name: 'Rua'
        },
        {
            id: 'lot',
            name: 'Lot/Número'
        },
        {
            id: 'client',
            name: 'cliente'
        },
        {
            id: 'finishDateBR',
            name: 'Data de Atendimento'
        },
        {
            name: 'Ação',
            formatter: (cell, row) => {
                return h('button', {
                    className: 'btn mainGradBK whiteFont',
                    onClick: () => selectedHelpDesk(row.cells[0].data)
                }, 'Detalhes');
            }
        },
        // {
        //     name: 'Ação',
        //     formatter: (cell, row) => {
        //         return h('button', {
        //             className: 'btn mainGradBK whiteFont',
        //             onClick: () => selectedHelpDesk(row.cells[0].data)
        //         }, 'PDF');
        //     }
        // },
    ])

    useEffect(() => {
        setIsShowTable(false)
    }, [helpDeskData])

    const selectedHelpDesk = (helpDeskID) => {
        history.push({
            pathname: '/chamado',
            // search: '?query=abc',
            state: { helpDeskID: helpDeskID }
        })
    }

    useEffect(() => {
        const asyncLoadStart = async () => {
            await findTasks()
            setIsLoading(false)
        }
        asyncLoadStart()
    }, [])

    useEffect(() => {
        if (!isShowTable) {
            setIsShowTable(true)
            setIsLoading(false)
        }
    }, [isShowTable])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div id="top">
                <MenuBar />
            </div>
            <div className="full padding100Top container">
                <div className="row">
                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <div className="row">
                            <div className="col-12">
                                <span className="title">Filtros</span>
                                <p className="subTitle">Filtrar Atendimentos</p>
                                <div className='mainDegLine'></div>
                            </div>
                        </div>
                        <div className="row top40">
                            <div className="col-12">
                                <div className='row top40'>
                                    <div className='col-12'>
                                        <p className='subTitle'>Data de atendimento</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-md-3 top10">
                                        <FieldText id="edtPeriodOne" name="edtPeriodOne" placeholder="De" />
                                    </div>
                                    <div className="col-12 col-md-3 top10">
                                        <FieldText id="edtPeriodTwo" name="edtPeriodTwo" placeholder="Até" />
                                    </div>
                                </div>
                                <div className="row top40">
                                    <div className='col-12'>
                                        <p className='subTitle'>Chamado</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3 top10">
                                        <FieldText id="edtProtocol" name="edtProtocol" placeholder="Protocolo" />
                                    </div>
                                    <div className="col-12 col-md-3 top10">
                                        <FieldText id="edtNeighborhood" name="edtNeighborhood" placeholder="Bairro" />
                                    </div>
                                    <div className="col-12 col-md-3 top10">
                                        <FieldText id="edtStreet" name="edtStreet" placeholder="Rua" />
                                    </div>
                                    <div className="col-12 col-md-3 top10">
                                        <FieldText id="edtUsername" name="edtUsername" placeholder="Equipe" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 top20 toRight">
                                    <div onClick={e => filterHelpDesks()} className='padding08'>
                                        <ButtonRectangle label="Filtrar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row top10 padding15">
                    <div className="col-12">
                        {
                            isShowTable &&
                            <TableGrid id="helpDeskGridId" title="Relatório" subTitle="Geranciar Atendimentos" columns={helpDeskColumns} data={helpDeskData} loadingFunction={setIsLoading} />
                        }
                    </div>
                </div>
            </div>

            {/* <div className="full padding100Top container">
                <div className="row top40">
                    <div className="col-12">
                        <div className='row'>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                                        <div className='row'>
                                            <p className='title'>Filtros</p>
                                            <div className='mainDegLine'></div>
                                        </div>
                                        <div className='row top40'>
                                            <p className='subTitle'>Data de atendimento</p>
                                        </div>
                                        <div className='row'>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtPeriodOne" name="edtPeriodOne" placeholder="Data" />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtPeriodTwo" name="edtPeriodTwo" placeholder="Data" />
                                            </div>
                                        </div>
                                        <div className="row top40">
                                            <p className='subTitle'>Chamado</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtProtocol" name="edtProtocol" placeholder="Protocolo" />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtNeighborhood" name="edtNeighborhood" placeholder="Bairro" />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtStreet" name="edtStreet" placeholder="Rua" />
                                            </div>
                                            <div className="col-12 col-md-3 top10">
                                                <FieldText id="edtUsername" name="edtUsername" placeholder="Equipe" />
                                            </div>
                                        </div>
                                        <div className="col-12 offset-sm-10 col-sm-2 top40">
                                            <div onClick={e => filterHelpDesks()} className='padding08'>
                                                <ButtonRectangle label="Filtrar" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-2 top60'>
                        {
                            isShowTable &&
                            tasks &&
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn mainGradBK whiteFont btn-block padding10 padding20Side delay"
                                table="table-to-xls-helpdesks"
                                filename="chamados"
                                sheet="Chamados"
                                buttonText="Download" />
                        }
                    </div>

                    <div className="col-12 secondColorBK smallShadow imgCardBox radius12 use100 padding30">
                        <table id='table-to-xls-helpdesks' className="table table-hover">
                            <thead>
                                {
                                    isShowTable &&
                                    tasks &&
                                    <TableFilter
                                        rows={tasks}
                                        onFilterUpdate={filterUpdated}
                                    >
                                        <th key="protocol" filterkey="protocol" className="cell" casesensitive={'true'} showsearch={'true'}>
                                            Protocolo
                                        </th>
                                        <th filterkey="statusHelpDesk.status">
                                            Status
                                        </th>
                                        <th filterkey="address.city.name">
                                            Cidade
                                        </th>
                                        <th filterkey="address.neighborhood">
                                            Bairro
                                        </th>
                                        <th filterkey="address.street">
                                            Rua
                                        </th>
                                        <th filterkey="address.lot">
                                            Lote/Número
                                        </th>
                                        <th filterkey="client.name">
                                            Cliente
                                        </th>
                                        <th filterkey="finishDateBR">
                                            Data de Atendimento
                                        </th>
                                        <th>
                                            Detalhes
                                        </th>

                                        <th>
                                            PDF
                                        </th>
                                    </TableFilter >
                                }
                            </thead>
                            <tbody>
                                {
                                    tasks &&
                                    tasks.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.protocol}<span className='notShow'>{"'"}</span></td>
                                            <td>{item.statusHelpDesk.status}</td>
                                            <td>{item.address.city.name}</td>
                                            <td>{item.address.neighborhood}</td>
                                            <td>{item.address.street}</td>
                                            <td>{item.address.lot}</td>
                                            <td>{item.client.name}</td>
                                            {/* <td>{item.openedDateBR}</td> * /}
                                            <td>{item.finishDateBR}</td>
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: "/chamado",
                                                        helpDeskID: item.id
                                                    }}
                                                >
                                                    <button className='btn mainGradBK whiteFont btn-block padding10 delay'>Detalhes</button>
                                                </Link>
                                            </td>
                                            <td>
                                                <form id={`pdfGeneratorForm${item.protocol}`} action="pdfGenerator.php" method="post">
                                                    <input type="hidden" name="tasks" value={'[' + JSON.stringify(item) + ']'} />
                                                    <button type="submit" className='btn mainGradBK whiteFont btn-block padding10 delay'>PDF</button>
                                                </form>
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr className='invisible inBold'>
                                    <td>Total de chamados</td>
                                    <td>{tasks ? tasks.length : 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
            <Footer />
        </div >
    )
}

export default HelpDesks