import React, { Component } from 'react';

import logoImg from '../../imgs/jj_logo.png'

function Footer(props) {
    return (
        <div className="full20">
            <div className="full20 center top60">
                <div className="container-fluid row">
                    <div className="row col-12">
                        <div className="col-6">
                            <h6><img src={logoImg} className="logoImg" /></h6>
                        </div>
                        <div className="col-6 text-right">
                            <h6>© {new Date().getFullYear()} Todos os direitos reservados</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;